import './Activity.css';
import React, { useRef } from "react";

export default function Card(props) {
    return (
        <div class="activity">
            <h4>Activity</h4>
           {props.text}
        </div>
    )
}