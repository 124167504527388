import './Theme1.css';

import React, { useRef, useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col} from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Card from "../components/Card";
import Activity from "../components/Activity";
import Reflection from "../components/Reflection";
import ThemeNav from './ThemesNav';

export default function Theme1() {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, []);

    console.log(offset);

  return (
    <div class="theme1">
      <table>
          <tr class="first-row">
              <td class="left-panel">
                <ThemeNav />
                <div>
                    <h3 class="page-name" id="SituatingPage">Situating the Tamil Community in Toronto</h3>
                    <a href="#Disclaimer"><h5>Disclaimer </h5></a>
                    <a href="#Portrayed"><h5>The Changing Portrayal of the Tamil Community</h5></a>
                    <a href="#Settler"><h5>Settler Colonialism: What does it mean to be Tamil settlers?</h5></a>
                    <a href="#Addressing"><h5>Addressing Anti-Black racism within the Tamil community</h5></a>
                </div>
                <div class="d-flex justify-content-between"/>
              </td>
              <td class="right-panel" id="right-panel">
                  <div class="container-holder">
                    <div id="Disclaimer" class="text-container-md">
                        <h2  class="header">Disclaimer</h2>
                        <p class="text">
                        Some of the resources in this section are aimed at “South Asians.” Formally, we fall under this category as people who originate from what is currently known as Sri Lanka. However, our experiences have often been much different, and indeed, (Eelam) Tamils are often underrepresented in South Asian discourse. <br /><br />

                        Caste and the different forms of migration also inform Tamil people’s experiences in the diaspora. Further, many Tamil people do not fall within the “model minority” or have made efforts to resist this label. All of this affords nuance to this discourse; our experience is not a monolith. <i>Simply take what resonates with you.</i> 
                        </p>
                    </div>  
                    <div id="Portrayed" class="text-container-md">
                        <h2 class="header">The Changing Portrayal of the Tamil Community</h2>
                        <p class="text">
                        The positioning of Canada as a safe haven for Tamil people has often meant the realities of the Tamil diaspora are erased or ignored. The Tamil diaspora has a storied history in Toronto, though much of it has evaded the public imagination in recent years. Until recently, the <a href="https://www.aclrc.com/racialization">racialization</a> of Tamils in Toronto constructed a specific identity intimately linked to the Tamil resistance movement.  Now, the identity is largely linked to the economic and political power of the community, creating a monolithic identity of success. <br/><br/>
                        Nevertheless, the racialization of Tamils in Toronto has been deployed for political agendas across party lines. For example, conservative media and political leaders leveraged the Tamil resistance to bolster support for the War on Terror and anti-immigration policies. Conversely, centrist and left-leaning discourse has strategically co-opted the Tamil identity and certain aspects of the resistance movement to further Canada’s portrayal as a leader in human rights and a multicultural utopia. <br/><br/>
                        To disrupt these narratives and to build solidarity with other communities in Canada, we must look back at our history in Toronto. In doing so, we can afford nuance to the Toronto Tamil identity and assume agency over its narrative, thereby resisting its co-optation for partisan agendas.
                        </p>
                        <div class="read-more"><a href="https://briarpatchmagazine.com/articles/view/when-memory-outlives-toronto-tamil" target="_blank"><p>Learn More</p><span class="read-more-arrow">&nbsp;&nbsp;&#8594;</span></a></div>
                        <div class="subsection">
                            <h4>“Divided” and “Violent”: The Tamil Narrative Misconstrued by the Canadian Mainstream</h4>
                            <p>In the 1990s and early 2000s, many articles painted the community as “divided” and “violent.” Around this time, “Tamil gangs” featured prominently in mainstream discourse. “Tamil gangs” were largely formed in response to exclusion and marginalization. As Niluja Albert explains, “it all began with defending themselves from escalating acts of neighborhood bullying by older Caucasian boys.” Indeed, what is often left out of narratives about “gangs”  is that they fill needs unmet by existing social infrastructure. </p>
                        </div>
                        <Row>
                            <Col id="card-col-3"><Card name="Branded by Terror: Toronto Tamils Stand Together" link="https://theeyeopener.com/2001/10/branded-by-terror-torontos-tamils-stand-together/"/></Col>
                            <Col id="card-col-3"><Card name="Breaking Down The 90s – A Look Back at Tamil Gangs" link="http://old.tamilculture.com/breaking-down-the-90s-a-look-back-at-tamil-gangs/"/></Col>
                            <Col id="card-col-3"><Card name="'Far too many deaths' | The Toronto Star" link="https://archive.ph/4Zsvv#selection-1197.0-1197.21"/></Col>
                        </Row>
                        <div class="subsection">
                            <h4>Racism and Solidarity in the 1990s</h4>
                            <p>While Toronto is often painted as the epitome of multiculturalism, Tamils confronted—and continue to endure—vicious racism. For example, in 1993, when Sivarajah Vinasithamby—a Tamil refugee—was going home after his shift as a dishwasher, he was <a href="https://dbpedia.org/page/Heritage_Front">brutally attacked by a mob</a> leaving a White Power concert. The attack left him paralyzed.<br/><br/>

                            However, it is also important to recognize the community organizing efforts that emerged around the time. Indeed, in many ways, the 1990s set the foundation for contemporary multiracial solidarity in Toronto. In the summer of 1992, catalyzed by the Rodney King verdict in Los Angeles and the lethal police shootings of Black men in Toronto, the <a href="https://nowtoronto.com/news/yonge-street-riot-documentary"> Yonge Street Riots</a> called attention to anti-Black racism. A year later, after attacks against Tamil immigrants by neo-nazi groups—including the assault of Sivarajah Vinasithamby—and the persistence of anti-Black racism, the <a href="https://cjc-online.ca/index.php/journal/article/download/1778/1902?inline=1#ababdfehi">Toronto Coalition Against Racism</a> was formed. As Raghu Krishan—a founding member of TCAR <a href="https://this.org/2003/01/01/remembering-anti-racism/">writes</a>—“TCAR saw the Tamil response as an example of the kind of mobilization that was possible when non-whites themselves took the lead and built alliances.” 
                            </p>
                        </div>
                        <div class="subsection">
                            <h4>The Tamil Resistance in Toronto</h4>
                            <p>During the height of the Tamil resistance, community members were painted as “terrorists” and “criminals.” As outlined in the section entitled "<a href="/policing#TheCriminalizationOfTamilExistence">The criminalization of Tamil existence and resistance in the post-911 era</a>", the Canadian state’s designation of the Liberation Tigers of Tamil Eelam (LTTE) and World Tamil Movement (WTM) as terrorist organizations exacerbated these stereotypes.  
                            </p>
                        </div>
                        <Row>
                            <Col id="card-col-3"><Card name="Images of Resistance: An Archive of Action | Myseum of Toronto" link="http://www.myseumoftoronto.com/programming/images-resistance-photo-exhibition/"/></Col>
                            <Col id="card-col-3"><Card name="Tamil Protests: Your Reaction" link="https://toronto.citynews.ca/2009/05/11/tamil-protests-your-reaction/"/></Col>
                            <Col id="card-col-3"><Card name="This is not a war on terror. It is a racist war on all Tamils | Arundhati Roy" link="https://www.theguardian.com/commentisfree/2009/apr/01/sri-lanka-india-tamil-tigers"/></Col>
                        </Row>
                        <Row>
                            <Col id="card-col-3"><Card name="Images of Resistance | Myseum of Toronto" link="https://youtu.be/9Y8CLOo3DzQ"/></Col>
                            <Col id="card-col-3"><Card name="Tamils Take to the Gardiner" link="https://torontoist.com/2009/05/tamils_take_to_the_gardiner/"/></Col>
                        </Row>
                        <div class="subsection">
                            <h4>The Tamil Diaspora in the 2010s and 2020s</h4>
                            <p>In more recent years, the community has been lauded as a model of resilience, success, and hard work. While this is undeniably true, it is important to consider why and how the narrative shifted. 
                            </p>
                            <Activity
                            text={
                                <p><i>Part 1</i>: Read these articles about the Tamil community. <br/><br/>

                                <a href="https://archive.macleans.ca/article/1996/4/29/a-divided-community">A Divided Community</a> (2001) <br/>
                                <a href="https://www.macleans.ca/general/tamil-questions-that-cant-be-asked/">Tamil questions that can’t be asked</a> (2009) <br/><br/>
                                 
                                Reflection Questions:<br/>
                                <ol>
                                    <li>As a Tamil person, do you believe these articles are true? </li>
                                    <li>Do you see similarities in the portrayal of the Tamil community in these articles to other communities today?</li>
                                    <li>Why are resistance movements often portrayed like this? </li>
                                </ol>
                                
                                <p><i>Part 2</i>: Read these statements released by politicians in more recent years.</p>
                                
                                <a href="https://www.canada.ca/en/canadian-heritage/news/2020/12/statement-by-minister-chagger-on-tamil-heritage-month.html">Statement by Minister Chagger on Tamil Heritage Month</a><br />
                                <a href="https://pm.gc.ca/en/news/statements/2021/01/14/statement-prime-minister-thai-pongal">Statement by the Prime Minister on Thai Pongal</a><br/><br/>
                                
                                Reflection Questions:<br/>
                                <ol>
                                    <li>Why do you think the narrative has shifted? </li>
                                    <li>What has the shift done for the Tamil resistance movement? </li>
                                </ol>
                            </p>
                            }
                            />
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://curve.carleton.ca/system/files/etd/5a8c1376-cd15-4984-ad17-83aca2072150/etd_pdf/27a72c50e323d7f96c592b97af30830c/boyd-boatpeopleandterroriststhemediadrivenmoral.pdf" target="_blank">Boat People and Terrorists: The media-driven moral panic and double consciousness of the Tamil diaspora in Canada</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="http://www.theinclusionsolution.me/demystifying-internalized-oppression-being-the-model-minority-isnt-a-compliment-how-internalizing-the-model-minority-myth-does-more-harm-than-good-asian-american/" target="_blank">Demystifying Internalized Oppression: Being the “Model Minority” isn’t a compliment: How internalizing the Model Minority Myth does more harm than good</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            </div>
                        </div>
                    </div> 
                    <div id="Settler" class="text-container-md">
                        <h2 class="header">Settler Colonialism: What does it mean to be Tamil settlers?</h2>
                        <p class="text">
                        As Tamil people, we are displaced, colonized people who pursue self-determination and sovereignty in our ancestral homeland. At the same time, we find ourselves implicated in the settler colonial project of the Canadian state.  While our ancestral lands were colonized, we now benefit from settler colonialism. As <a href="https://briarpatchmagazine.com/articles/view/decolonizing-together">Harsha Walia</a> writes, for racialized migrants, “being responsible for decolonization can require us to locate ourselves within the context of colonization in complicated ways, often as simultaneously oppressed and complicit.”<br/><br/>
                        </p>
                        <div class="quote-small-holder">
                            <h4 class="quote-small-left" >“Even if we come from colonized places, the structural conditions of non-Indigenous and non-Black people of colour means that we benefit from and perpetuate settler colonialism in Canada. In fact, many non-Indigenous, non-Black people of colour buy into colonial capitalism and allow themselves to be weaponized for anti-Indigeneity and anti-Blackness as ‘model minorities.’” 
                            </h4>
                            <h3 class="author">Brannavy Jeyasundaram</h3>
                            <h3 class="author explain">Beneath the Ashes: Remembering Black July and the Violence Before</h3>
                        </div>
                        <p class="text">
                        Gillian Philipupillai aptly states in their thesis, <i>The Marking of Tamil Youth as Terrorists and the Making of Canada as a White Settler Society</i>, “...white settler colonialism, occupation, and denial of Indigenous sovereignty necessitate racial management and the making of racialized bodies into shifting borders constituting who belongs and who does not, who deserves protection, and who is a threat, who is a citizen, and who is a savage.”<br/><br/>

                        As previously mentioned, the Tamil identity has been deployed for various political agendas across party lines. However, the locus of many of these agendas is ensuring the futurity of the settler colonial state of Canada and obscuring its genocide of Indigenous Peoples. For example, the racialization of Tamils as “terrorists” and “criminals”—part of the broader War on Terror-—has resulted in the framing of Tamils as importing violence and terror into Canada, “such that the originary violence, genocide and terror of white settler colonialism against Indigenous peoples can continue to be legitimized and erased” (Philipupillai, p.10). <br/><br/>

                        At the same time, the Canadian state is increasingly leveraging the Tamil identity—now more commonly associated with a degree of economic and political success—to bolster the veneer of Canadian multiculturalism. Insidiously, this veneer of multiculturalism obscures Canada’s ongoing genocide of Indigenous Peoples and erases racial inequities. As <a href="https://rabble.ca/anti-racism/multiculturalism-not-anti-racism/">Khadijah Kanji explains</a>, “multiculturalist discourse allows Canada to benefit from a system that systematically produces displaced people — while extracting moral currency for granting conditional entry to a fraction of its victims.”<br/><br/> 
                        </p>
                        <Row>
                            <Col id="card-col-2"><Card name="The Marking of Tamil Youth as Terrorists and the Making of Canada as a White Settler Society | Gillian Philipupillai" link="https://tspace.library.utoronto.ca/bitstream/1807/42640/1/Philipupillai_Gillian_G_MA_thesis.pdf"/></Col>
                            <Col id="card-col-2"><Card name="Loving, Working, and Living on Stolen Land: People of Colour, Settler Colonialism & White Supremacy " link="https://reconciliationsyllabus.wordpress.com/2018/12/08/loving-working-and-living-on-stolen-land-people-of-colour-settler-colonialism-white-supremacy/"/></Col>
                        </Row>
                        <Row>
                            <Col id="card-col-2"><Card name="From parks to prisons, decolonization is the responsibility of people of colour, too" link="https://www.thestar.com/opinion/contributors/2019/11/15/shama-rangwala-from-parks-to-prisons-decolonization-is-the-responsibility-of-people-of-colour-too.html"/></Col>
                            <Col id="card-col-2"><Card name="Indigenous Ally Toolkit | Segal Centre" link="https://segalcentre.org/common/sitemedia/201819_Shows/ENG_AllyTookit.pdf"/></Col>
                        </Row>
                        <Row>
                            <Col id="card-col-2"><Card name="Settlers Take Action | On Canada Project" link="https://oncanadaproject.ca/settlerstakeaction"/></Col>
                            <Col id="card-col-2"><Card name="Decolonizing together | Harsha Walia" link="https://briarpatchmagazine.com/articles/view/decolonizing-together"/></Col>
                            <Col id="card-col-2"><Card name="Settler Colonialism Primer" link="https://unsettlingamerica.wordpress.com/2014/06/06/settler-colonialism-primer/"/></Col>
                        </Row>
                        <Reflection text={
                            <>
                            <ol>
                                <li>As Tamil people—as displaced, colonized, and oppressed people—what does it mean to be settlers?</li>
                                <li>What does it mean to seek refuge on stolen land?</li>
                                <li>Following the Gardiner Expressway protests, Ontario's interim Opposition Leader Bob Runciman (Progressive Conservative) complained about the Ontario government’s inability to uphold <a href="https://www.cp24.com/police-tolerance-of-tamil-protests-may-lessen-after-highway-storming-expert-1.397525?cache=">“the rule of law”</a> during the takeover of the highway, as well as in ongoing demonstrations in Caledonia (often referred to as the <a href="https://www.aptnnews.ca/national-news/conflict-in-caledonia-a-timeline-of-the-grand-river-land-dispute/">Grand River land dispute</a> —wherein the Six Nations of the Grand River occupied the Douglas Creek Estates (DCE) subdivision to reclaim the unsurrendered territory of the Six Nations Confederacy). A little over a decade later, the Ontario Progressive Conservative party established <a href="https://www.ontario.ca/laws/statute/21t11">Tamil Genocide Education Week</a>.
                                    <ul>Why do you think there is not an Indigenous Genocide Education week in Canada?</ul>
                                    <ul>Why did the attitude of the Ontario Progessive Conservatives shift over the past decade? </ul>
                                    <ul>How do you reconcile this with the ongoing land disputes in Caledonia, including the heavily-policed 1492 Land Back Lane?</ul>
                                </li>
                                <li>As displaced people resisting Tamil genocide on stolen Indigenous land, inherent complexities manifest themselves. These complexities must serve as a launching point for discussions within our community to develop a praxis of resistance premised on solidarity. What does it mean to pursue Tamil nationhood and self-determination in a state whose existence is premised on Indigenous genocide?</li>
                            </ol>
                            </>
                        }
                        /> 
                    </div> 
                    <div id="Addressing" class="text-container-md">
                        <h2 class="header">Addressing Anti-Black racism within the Tamil Community</h2>
                        <p class="text">
                        We must call it like it is—anti-Black racism persists within the Tamil community. This serves as an impediment to building multi-racial solidarity and furthering anti-racist efforts within Toronto. Our anti-racist efforts must call attention to the distinct modes and manifestations of anti-Black racism in Canadian institutions. 
                        </p>
                        <div class="subsection">
                            <h4>The Model Minority Myth</h4>
                            <p>Dismantling anti-Black racism within the Tamil community requires learning and unlearning. For example, the model minority myth that accompanies the increasing economic and political success of the Tamil community requires further interrogation. As Thamara Subramaniam writes, “...the term ‘Model Minority’ was not intended to be a compliment or celebration of respect for Asian Americans; it was intended to be a tool for manipulation and an excuse for oppression.” <br/><br/>

                            The success (defined in conventional terms) of “model” communities is used to dismiss the enduring systemic discrimination confronted by Black and Indigenous peoples in Canada. The myth is also harmful to members of the Tamil community; socializing us to conform to a specific form of success, defined in terms of academic or economic achievement. 
                        </p>
                        </div>
                        <Row>
                            <Col id="card-col-2"><Card name="Searching for anti-racism agendas in South Asian Canadian communities" link="https://theconversation.com/searching-for-anti-racism-agendas-in-south-asian-canadian-communities-142431"/></Col>
                            <Col id="card-col-2"><Card name="The Tamil Community & Black Lives Matter" link="https://youtu.be/_gqQyhgJjDI"/></Col>
                            <Col id="card-col-2"><Card name="South Asians and Black Lives (Tamil Translation)" link="https://dviyer.medium.com/south-asians-and-black-lives-b30adaba6a42"/></Col>
                        </Row>
                        <Row>
                            <Col id="card-col-2"><Card name="The Twitter feud that perpetuated anti-Black racism within the South Asian community | Tasheal Gill" link="https://www.5xfest.com/5xpress/the-twitter-feud-that-perpetuated-anti-black-racism-within-the-south-asian-community"/></Col>
                            <Col id="card-col-2"><Card name="It’s Time to Confront Anti-Blackness within the South Asian Diaspora" link="https://tamilculture.com/confronting-anti-blackness-within-the-south-asian-diaspora"/></Col>
                        </Row>
                        <Row>
                            <Col id="card-col-2"><Card name="Anti-Blackness, Caste & Colorism" link="https://www.sayhu.org/anti-blackness-caste-colorism"/></Col>
                            <Col id="card-col-2"><Card name="It Starts at Home: Confronting Anti-Blackness in South Asian Communities" link="https://queersouthasian.wordpress.com/2014/12/19/it-starts-at-home-confronting-anti-blackness-in-south-asian-communities/"/></Col>
                            <Col id="card-col-2"><Card name="On South Asian solidarity | The Michigan Daily" link="https://www.michigandaily.com/opinion/south-asian-solidarity/"/></Col>
                        </Row>
                        <Row>
                            <Col id="card-col-2"><Card name="The ‘model minority’ myth explained. What you need to know about how it has propped up anti-Asian racism in Canada" link="https://www.thestar.com/news/canada/2021/03/27/the-model-minority-myth-explained-what-you-need-to-know-about-how-it-has-propped-up-anti-asian-racism-in-canada.html?utm_source=Twitter&utm_medium=SocialMedia&utm_campaign=National&utm_content=model-minority"/></Col>
                             <Col id="card-col-2"><Card name="Letters for Black Lives (Tamil Translation)" link="https://lettersforblacklives.com/%E0%AE%85%E0%AE%A9%E0%AF%8D%E0%AE%AA%E0%AF%81%E0%AE%B3%E0%AF%8D%E0%AE%B3-%E0%AE%85%E0%AE%AE%E0%AF%8D%E0%AE%AE%E0%AE%BE-%E0%AE%85%E0%AE%AA%E0%AF%8D%E0%AE%AA%E0%AE%BE-%E0%AE%AE%E0%AE%BE%E0%AE%AE%E0%AE%BE-%E0%AE%85%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AF%88-%E0%AE%A4%E0%AE%BE%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AE%BE-%E0%AE%AA%E0%AE%BE%E0%AE%9F%E0%AF%8D%E0%AE%9F%E0%AE%BF-%E0%AE%95%E0%AF%81%E0%AE%9F%E0%AF%81%E0%AE%AE%E0%AF%8D%E0%AE%AA%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AE%BF%E0%AE%A9%E0%AE%B0%E0%AF%8D-%E0%AE%AE%E0%AE%B1%E0%AF%8D%E0%AE%B1%E0%AF%81%E0%AE%AE%E0%AF%8D-%E0%AE%A8%E0%AE%A3%E0%AF%8D%E0%AE%AA%E0%AE%B0%E0%AF%8D%E0%AE%95%E0%AE%B3%E0%AF%87-77f62e2a9dc0"/></Col>
                        </Row>
                        <Reflection text={
                            <>
                            <ol>
                                <li>What are some of the ways anti-Black racism manifests in Canada? In the Tamil Community?</li>
                                <li>Take a look at this <a href="https://www.instagram.com/p/CDAo91qAhPv/">graphic</a>. What did you learn? Does it open up gaps for unlearning and learning? </li>
                                <li>Think about the model minority myth. How has it impacted you? Have you played into it? Have you resisted it? How has it formed your perception of the Tamil community? Has it impacted how you perceive other communities? </li>
                                <li>Work through the <a href="https://www.wintersgroup.com/resources/#Thoughtpapers">Demystifying Internalized Oppression: A Reflection Guide</a>.</li>
                            </ol>
                            </>
                        }/>
                    </div>
                  </div>
              </td>
          </tr>
      </table>
    </div>
  );
}