import React, { useRef } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col} from "react-bootstrap";
import "./ThemesNav.css"

export default function ThemeNav(props) {
    const navDropdown = useRef(0);

    return (
        <div>         
            <h5><a class="themesClick" onClick={() => displayNav(true)}>View Themes</a></h5>
            <table class="dropdown" ref={navDropdown}>
                <img onClick={() => displayNav(false)} src="../assets/close.png" />
                <tr>
                    <td class="theme intro">
                        <a href="/"><h3>Introduction</h3></a>
                    </td>
                    <td class="theme one">
                        <a href="/situating-the-community#SituatingPage"><h3>Situating the Tamil Community in Toronto</h3></a>
                        <a href="/situating-the-community#Disclaimer"><h5>Disclaimer </h5></a>
                        <a href="/situating-the-community#Portrayed"><h5>The Changing Portrayal of the Tamil Community</h5></a>
                        <a href="/situating-the-community#Settler"><h5>Settler Colonialism: What does it mean to be Tamil settlers?</h5></a>
                        <a href="/situating-the-community#Addressing"><h5>Addressing Anti-Black racism within the Tamil community</h5></a>
                    </td>
                    <td class="theme two">
                        <a href="/policing#PolicingPage"><h3>Policing in Sri Lanka and Canada</h3></a>
                        <a href="/policing#About"><h5>About</h5></a>
                        <a href="/policing#Genocide"><h5>Tamil Genocide and the Police</h5></a>
                        <a href="/policing#Indigenous"><h5>Indigenous Genocide</h5></a>
                        <a href="/policing#Racism"><h5>Anti-Black Racism</h5></a>
                        <a href="/policing#Policing"><h5>Policing of Tamils in Canada</h5></a>
                    </td>
                    <td class="theme three">
                        <a href="/defund-the-police#DefundPage"><h3>Defund the Police</h3></a>
                        <a href="/defund-the-police#Safety"><h5>Community Safety</h5></a>
                        <a href="/defund-the-police#CanYTD"><h5>CanYTD</h5></a>
                        <a href="/defund-the-police#Violence"><h5>Interpersonal Violence</h5></a>
                        <a href="/defund-the-police#Defund"><h5>Defund the Police</h5></a>
                        <a href="/defund-the-police#Alternatives"><h5>The Alternatives: Current Initiatives and Efforts in Toronto</h5></a>
                        <a href="/defund-the-police#Action"><h5>Taking Action</h5></a>
                    </td>
                </tr>
            </table>
        </div>
    ); 

    function displayNav(shouldDisplayNav) {
        if (shouldDisplayNav) {
          navDropdown.current.style.opacity = 1;
          navDropdown.current.style.zIndex = 1;
          document.getElementById("surroundingDiv").style.overflow = "hidden";
        } else {
          navDropdown.current.style.opacity = 0;
          navDropdown.current.style.zIndex = -1;
          document.getElementById("surroundingDiv").style.overflowY = "scroll";
        }
      }
}