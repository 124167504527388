import './App.css';

import React, { useRef } from "react";
import { Navbar, Container, Nav, NavDropdown} from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Intro from "./components/Intro.js";
import Situating from "./components/Theme1";
import Policing from "./components/Policing.js";
import Defund from "./components/Defund.js";

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/situating-the-community">
            <Situating />
          </Route>
          <Route path="/policing">
            <Policing />
          </Route>
          <Route path="/defund-the-police">
            <Defund />
          </Route>
          <Route path="/">
            <Intro />
          </Route>
        </Switch>
    </Router>
  ); 

}