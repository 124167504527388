import "./Intro.css";

import React, { useRef } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Card from "../components/Card";
import Activity from "../components/Activity";

export default function Intro() {
  const navDropdown = useRef(0);

  return (
    <div>
      <Navbar expand="lg">
        <Navbar.Brand href="/">Building Otrumai</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="link-holder">
            <Nav.Link onClick={() => displayNav(true)} id="chapters">
              Themes
            </Nav.Link>
            {/* <Nav.Link href="/link">Contact</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <table class="dropdown" ref={navDropdown}>
        <img onClick={() => displayNav(false)} src="../assets/close.png" />
        <tr>
          <td class="theme intro">
            <a href="/">
              <h3>Introduction</h3>
            </a>
          </td>
          <td class="theme one">
            <a href="/situating-the-community#SituatingPage">
              <h3>Situating the Tamil Community in Toronto</h3>
            </a>
            <a href="/situating-the-community#Disclaimer">
              <h5>Disclaimer </h5>
            </a>
            <a href="/situating-the-community#Portrayed">
              <h5>The Changing Portrayal of the Tamil Community</h5>
            </a>
            <a href="/situating-the-community#Settler">
              <h5>
                Settler Colonialism: What does it mean to be Tamil settlers?
              </h5>
            </a>
            <a href="/situating-the-community#Addressing">
              <h5>Addressing Anti-Black racism within the Tamil community</h5>
            </a>
          </td>
          <td class="theme two">
            <a href="/policing#PolicingPage">
              <h3>Policing in Sri Lanka and Canada</h3>
            </a>
            <a href="/policing#About">
              <h5>About</h5>
            </a>
            <a href="/policing#Genocide">
              <h5>Tamil Genocide and the Police</h5>
            </a>
            <a href="/policing#Indigenous">
              <h5>Indigenous Genocide</h5>
            </a>
            <a href="/policing#Racism">
              <h5>Anti-Black Racism</h5>
            </a>
            <a href="/policing#Policing">
              <h5>Policing of Tamils in Canada</h5>
            </a>
          </td>
          <td class="theme three">
            <a href="/defund-the-police#DefundPage">
              <h3>Defund the Police</h3>
            </a>
            <a href="/defund-the-police#Safety">
              <h5>Community Safety</h5>
            </a>
            <a href="/defund-the-police#CanYTD">
              <h5>CanYTD</h5>
            </a>
            <a href="/defund-the-police#Violence">
              <h5>Interpersonal Violence</h5>
            </a>
            <a href="/defund-the-police#Defund">
              <h5>Defund the Police</h5>
            </a>
            <a href="/defund-the-police#Alternatives">
              <h5>
                The Alternatives: Current Initiatives and Efforts in Toronto
              </h5>
            </a>
            <a href="/defund-the-police#Action">
              <h5>Taking Action</h5>
            </a>
          </td>
        </tr>
      </table>
      <div class="landing">
        <h1>Building Otrumai</h1>
        <h4>Set the foundation for building solidarity</h4>
        <img class="arrow" src="../../assets/arrow.png" />
      </div>
      <div class="purpose">
        <div class="text-container">
          <div class="d-flex justify-content-between">
            <h2>Welcome</h2>
            <h2>வணக்கம்</h2>
          </div>
          <p>
            Tamil people have a storied history in Toronto, grounded in
            community, resilience, and resistance. Fleeing persecution in Sri
            Lanka, they arrived in “Canada” in droves, especially in the years
            immediately after Black July. Now, “Canada”—and specifically
            Toronto—is home to the largest Tamil diaspora outside South Asia.
            Indeed, Toronto afforded the community something they no longer had
            in their ancestral land; a place to simply be. <br /> <br />
            With that said, the migration and settlement process in Toronto was
            not easy. Still reeling from the trauma of the war, Toronto Tamils
            had to pick themselves up and forge community in a foreign land. In
            fact, despite conventional narrative, Toronto was not necessarily a
            welcoming space for Tamils. Indeed, many members of the community
            found—and still find—themselves in vulnerable or precarious
            positions. In addition, putting roots down in Toronto has never
            meant forgetting back home. The community remains committed to the
            Tamil resistance and seeking justice for Tamil genocide. The
            political coming of age of the Toronto Tamil community was arguably
            in 2009, culminating in the Gardiner Expressway protests. Despite
            the vehement vilification of the Tamil resistance in the media and
            within political spaces, the community persisted. <br /> <br />
            Now, more than a decade later, the Tamil community is amassing
            economic and political power. They are increasingly reflected in
            electoral politics, garnering success in economic endeavours, and
            making names for themselves in diverse spaces, including in the
            arts. Undoubtedly, the narrative surrounding the community is
            shifting. However, as a result, the community has often been painted
            with a broad brush, glossing over the diverse ways Toronto Tamils
            have experienced life in Canada. <br /> <br />
            Indeed, the story of Tamil people in “Canada” is often deployed by
            the Canadian state to construct a narrative of generosity and
            benevolence. However, this is a dangerous narrative that erases the
            state violence, colonization, and displacement engendered by the
            Canadian state here, and across the world. Indeed, Canada is a
            settler colony and a state that has conducted genocide. <br />{" "}
            <br />
            As such, this resource will disrupt this narrative and set the
            foundation for building solidarity with other oppressed, displaced,
            and colonized peoples. The Tamil resistance is a movement against
            the oppression, colonization, and displacement of Tamil people in
            Sri Lanka. It is also a movement intimately linked to other
            struggles.
          </p>
        </div>
      </div>
      <div class="purpose-2">
        <div class="text-container">
          <div class="land-acknowledgement">
            <h2>Land Acknowledgement</h2>
            <p>
              This resource was created by a Tamil settler on the traditional,
              unceded, and ancestral territories of the Mississaugas of the
              Credit, the Attiwonderonk, the Anishinabek, and the Haudenosaunee.
              I acknowledge that this land has been taken from its original
              caretakers, who have been here from time immemorial. I also
              recognize that this land is covered by the Dish with One Spoon
              Wampum Belt Covenant, a treaty between the Anishinaabe, the
              Mississaugas of the Credit, and the Haudenosaunee (listen to Elder
              Duke Redbird’s poem, <i>Dish With One Spoon</i>{" "}
              <a href="https://youtu.be/9fa3Nplmg6s">here</a>). <br />
              <br />
              Land acknowledgements are merely the first step in unmaking the
              settler colony that is Canada. As a settler of this land, I
              recognize the role I have played in legitimizing so-called Canada,
              and am committed to working in solidarity with Indigenous Peoples
              in their resistance to colonial violence (listen to Elder Duke
              Redbird discuss why land acknowledgements without action can be
              problematic <a href="https://youtu.be/fYOQaNu0Btk">here</a>). In
              the fight for justice and accountability for the Tamil genocide,
              we must also seek justice and accountability for the genocide of
              the Indigenous Peoples of Turtle Island. <br />
              <br />
              If you have not done so already, I urge you to learn about the
              histories, the stories, and the enduring realities of the lands
              you are situated on (find out whose lands you are on{" "}
              <a href="https://youtu.be/9fa3Nplmg6s">here</a>).
            </p>
          </div>
        </div>
        <div class="text-container">
          <table class="about">
            <tr>
              <td class="header">
                <h2>About this Resource</h2>
              </td>
              <td class="text">
                <p>
                  This resource will be grounded in the context of the Defund
                  the Police movement in Toronto, drawing upon histories of
                  policing in Canada and Sri Lanka. It builds upon the tradition
                  of political education in social movements, but political
                  education does not create change alone. Understanding existing
                  systems is a prerequisite to changing them, but study must
                  necessarily be paired with struggle. Thus, this resource
                  serves as a launching point for meaningful work conducted in
                  solidarity and friendship with other communities. <br />
                  <br />
                  It is organized thematically: <br />
                  <br />
                  <a href="/situating-the-community#SituatingPage">
                    Situating the Tamil Community in Toronto
                  </a>
                  <br />
                  <a href="/policing#PolicingPage">
                    Policing in Sri Lanka and Canada
                  </a>
                  <br />
                  <a href="/defund-the-police#DefundPage">
                    The Defund the Police Movement in Toronto
                  </a>
                  <br />
                  <br />
                  It will introduce important points, and then share recommended
                  readings or videos. On occasion, further materials will be
                  included, in case a particular topic is of particular interest
                  to you. The information will be punctuated with reflection
                  questions, encouraging you to take pause and reflect. <br />
                  <br />
                  This resource can be used in varied and diverse ways. For
                  example, you may choose to create a study group, work through
                  it on your own, read a few articles, read all the articles and
                  the further readings, use it to facilitate conversation within
                  your circles, or whatever else works for you. <br />
                  <br />
                  If you are looking for Tamil translations of words (such as
                  colonialism, First Nations Peoples, solidarity), you can find
                  some{" "}
                  <a
                    class="text-color"
                    href="https://southasiantranslations.carrd.co/"
                  >
                    here
                  </a>{" "}
                  (credit: Prinita Thevarajah)
                </p>
              </td>
            </tr>
          </table>
          <div class="additional-reading mb-0">
            <h4>Additional Readings</h4>
            <a
              href="https://politicaleducation.org/political-education-in-a-time-of-rebellion/"
              target="_blank"
            >
              Political Education in a Time of Rebellion | Center for Political
              Education
            </a>
            &nbsp;&nbsp;&nbsp;&#8594;
            <br />
          </div>
        </div>
        <div class="text-container position-relative">
          <div class="personal-reflection">
            <h2>A Personal Reflection [Editor's Note]</h2>
            <p>
              This is a <b>living document</b>; it will continue to evolve as we
              learn from each other, as well as other communities and movements.
              I was hesitant to make this resource public until it was
              “perfect,” but perfection is an untenable standard and an
              impediment to action. As such, I am choosing to share this
              resource with full transparency that it is far from perfect or
              complete. I am learning and unlearning <i>with you.</i>
              <br />
              <br />I am always open to starting a dialogue and building
              community. If you would like to reach out with comments,
              criticisms, suggestions, and thoughts, email me at{" "}
              <a href="mailto:buildingotrumai@gmail.com">
                buildingotrumai@gmail.com
              </a>{" "}
              <br />
              <br />
              Working on this resource has been a labour of love. I firmly
              believe that to build the futures we seek, we must work in
              solidarity and friendship with other communities. As you engage in
              this work, do not forget that rest is also resistance. Take care
              of yourself (and your community).
              <br />
              <br />
              In solidarity,
              <br />
              Sinthura
              <br />
              <br />
              This website was created by{" "}
              <a href="bit.ly/49xcXZy">Aliya Rajwani</a> and I am immensely
              grateful to her. I also wish to thank the Tamil Youth Fellowship
              for their continued support and Jessica for her always insightful
              comments.
            </p>
          </div>
          <div class="personal-reflection-design"></div>
        </div>
        <div class="text-container">
          <h2>
            Take care of <span class="text-color">yourself</span>. Take care of
            your <span class="text-color">community</span>.
          </h2>
          <p>
            This is a resource rife with heavy content; the type that can weigh
            heavy on your soul. It is also presented as a call to action to
            build solidarity with the Defund the Police movement in Toronto.
            This is work grounded in community, but it must also be grounded in
            care. <br />
            <br />
            As such, it is important to take care of yourself; we have to show
            up for ourselves before we can show up for anyone else. As Angela
            Davis states, caring for ourself enables us “to bring our entire
            selves into the movement.” <br />
            <br />
            Caring for ourself is also an act of resistance. Audre Lorde has
            famously stated, “caring for myself is not self-indulgence, it is
            self-preservation, and that is an act of political warfare.” While
            existing within systems that dehumanize and devalue us, it is
            powerful to affirm the care we deserve. <br />
            <br />
            However, we also need to shift to a paradigm of community care.
            Nakita Valerio has famously stated, “shouting ‘self-care’ at people
            who actually need ‘community care’ is how we fail people.” Thus, I
            also encourage you to think about committing to community care,
            which manifests in myriad ways. <br />
            <br />
          </p>
          <div class="additional-reading mb-0">
            <h4>Additional Readings</h4>
            <a href="https://youtu.be/Q1cHoL4vaBs" target="_blank">
              Radical Self Care: Angela Davis
            </a>
            &nbsp;&nbsp;&nbsp;&#8594;
            <br />
            <a
              href="https://medium.com/@115531943/on-self-community-care-7cd8d3498cde"
              target="_blank"
            >
              On Self & Community Care | Shannon Greene
            </a>
            &nbsp;&nbsp;&#8594;
            <br />
            <a
              href="https://mashable.com/article/community-care-versus-self-care"
              target="_blank"
            >
              Self-care isn't enough. We need community care to thrive
            </a>
            &nbsp;&nbsp;&nbsp;&#8594;
            <br />
            <a href="https://www.hrresilience.org/" target="_blank">
              Human Rights Resilience Project
            </a>
            &nbsp;&nbsp;&nbsp;&#8594;
            <br />
            <a
              href="https://locallove.ca/issues/the-revolutionary-origins-of-self-care/#.YXSUu9nMJH0"
              target="_blank"
            >
              The revolutionary origins of self-care
            </a>
            &nbsp;&nbsp;&nbsp;&#8594;
            <br />
          </div>
          <Activity text="Make a list of activities that spark joy and/or make you feel cared for. Keep this list handy and come back to it as you need." />
        </div>
        {/* <div class="text-container-100 activity">
          <div class="text-container-activity">
            <table>
              <tr>
                <td class="icon"><img src="../../assets/activity.png"/></td>
                <td class="title"><h2>Activity</h2></td>
              </tr>
            </table>
            <p>Make a list of activities that spark joy instantaneously and/or make you feel cared for. Keep this list handy and come back to it as you need. </p>
          </div>
        </div> */}
        <div class="text-container">
          {/* <h3 class="author-2">Pierre Beaulieu-Blais</h3> */}
          <h2>Transnational Solidarity</h2>
          <div class="quote-small-holder">
            <h2 class="quote-small-left">
              "From one community of resistance to another, we welcome you. As
              people who have also lost our land and been displaced because of
              colonialism and racism, we say Open All the Borders! Status for
              All!"
            </h2>
            <h3 class="author">Pierre Beaulieu-Blais</h3>
            <h3 class="author explain">Anishinaabe member of NOII-Ottawa</h3>
          </div>
          <table class="solidarity">
            <tr>
              <td class="text-1">
                <p>
                  “From one community of resistance to another, we welcome you.
                  As people who have also lost our land and been displaced
                  because of colonialism and racism, we say Open All the
                  Borders! Status for All!” - Pierre Beaulieu-Blais, an
                  Anishinaabe member of NOII-Ottawa, expresses solidarity with
                  the Tamil refugees aboard the MV Sun Sea at a demonstration
                  outside Citizenship and Immigration Canada offices. <br />
                  In our current political and social infrastructure, we
                  inadvertently adopt a scarcity mindset. In the context of
                  social movements, it perpetuates the false understanding that
                  furthering one movement comes at the expense of another. As
                  Audre Lorde writes in Sister Outsider:
                  <br />
                  “​​It is based upon the false notion that there is only a
                  limited and particular amount of freedom that must be divided
                  up between us, with the largest and juiciest pieces of liberty
                  going as spoils to the victor or the stronger. So instead of
                  joining together to fight for more, we quarrel between
                  ourselves for a larger slice of the one pie.”
                  <br />
                  <br />
                </p>
              </td>
              <td class="text-2">
                <p>
                  To work against this scarcity mindset, we must build
                  solidarity with other movements and communities. We are
                  stronger and better when we work together. As such, this
                  resource seeks to set the foundation for the Tamil community
                  to build transnational solidarity with other communities. It
                  should be noted, however, that solidarity must not be informed
                  by an expectation of reciprocity. Rather, it should stem from
                  the understanding that working in solidarity with other
                  communities is necessary to create the new worlds we seek. As
                  Lilla Watson famously stated, “If you have come here to help
                  me you are wasting your time, but if you have come because
                  your liberation is bound up with mine, then let us work
                  together.”
                </p>
              </td>
            </tr>
          </table>
          <div class="additional-reading">
            <h4>Additional Readings</h4>
            <a
              href="https://drive.google.com/file/d/13HguFWN1NM6jdCCWpQHX-wlB0PqYW9lB/view"
              target="_blank"
            >
              Ferguson Is Everywhere | Deepa Iyert
            </a>
            &nbsp;&nbsp;&nbsp;&#8594;
            <br />
            <a
              href="https://www.studioatao.org/post/unlearning-scarcity-cultivating-solidarity-a-toolkit-for-the-asian-american-community"
              target="_blank"
            >
              Unlearning Scarcity, Cultivating Solidarity | Studio ATAO
            </a>
            &nbsp;&nbsp;&#8594;
            <br />
          </div>
        </div>
      </div>
    </div>
  );

  function displayNav(shouldDisplayNav) {
    if (shouldDisplayNav) {
      navDropdown.current.style.opacity = 1;
      navDropdown.current.style.zIndex = 1;
      document.getElementById("surroundingDiv").style.overflow = "hidden";
    } else {
      navDropdown.current.style.opacity = 0;
      navDropdown.current.style.zIndex = -1;
      document.getElementById("surroundingDiv").style.overflowY = "scroll";
    }
  }
}
