import './Card.css';
import React, { useRef } from "react";

export default function Card(props) {
    return (
        <a href={props.link} target="_blank" id="card-link">
            <div class="card-container">
                <div class="card-holder" >
                    <h5>{props.name}</h5>
                    <div>
                        <h4 class="hover-text">{props.description}</h4>
                        <p class="card-arrow">&#8594;</p>
                    </div>   
                </div>
            </div>
        </a>
    ); 
}