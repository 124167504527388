import './Defund.css';

import React, { useRef, useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col} from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Card from "../components/Card";
import Activity from "../components/Activity";
import Reflection from "../components/Reflection";
import ThemesNav from "../components/ThemesNav";
import ReflectionForm from './ReflectionForm';

export default function Theme1() {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, []);

    console.log(offset);

  return (
    <div class="theme1">
      <table>
          <tr class="first-row">
              <td class="left-panel">
                <ThemesNav/>
                <div>
                    <h3 class="page-name" id="DefundPage">Defund the Police</h3>
                    <a href="#Safety"><h5>Community Safety</h5></a>
                    <a href="#CanYTD"><h5>CanYTD</h5></a>
                    <a href="#Violence"><h5>Interpersonal Violence</h5></a>
                    <a href="#Defund"><h5>Defund the Police</h5></a>
                    <a href="#Alternatives"><h5>The Alternatives: Current Initiatives and Efforts in Toronto</h5></a>
                    <a href="#Action"><h5>Taking Action</h5></a>
                </div>
                <div class="d-flex justify-content-between">
                </div>
              </td>
              <td class="right-panel" id="right-panel">
                  <div class="container-holder">
                    <div id="Safety" class="text-container-md">
                        <Reflection text={
                            <>
                            <p>So far, we have problematized the concept of policing. Before moving into the Defund the Police theme, take a second to pause and reflect.</p>
                            <ol>
                                <li>What have you learned so far?</li>
                                <li>How have your views changed?
                                <ReflectionForm id="HowHaveYourViewsChanged"/>
                                </li>
                                <li>Why do you think we started with the histories and contemporary realities of policing? How will it inform how you proceed through the rest of this resource?</li>
                                <li>What and who previously informed your ideas of policing? Have these ideas changed? Why or why not?</li>
                            </ol>
                            </>
                        }/>
                        <h2  class="header">Community Safety</h2>
                        <div class="quote-small-holder">
                            <h2 class="quote-small-left">“The safest communities don’t have the most cops. They have the most resources.”</h2>
                            <h3 class="author">Jillian Johnson</h3>
                            <h3 class="author explain">Durham Mayor Pro Tempore</h3>
                        </div>
                        <p class="text">
                        The Defund the Police movement asserts that other futures are possible. It demands that we shift towards community-based strategies to safety and wellbeing. In light of what we have learned in this resource, it becomes clear that the police have—and always will be—agents of the state. The same people tasked with enforcing genocidal or racist policies simply cannot be the same people responsible for community safety. That said, before delving into defunding the police, it is helpful to forge a preliminary idea of what community safety means. <br /><br/>
                        </p>
                    
                    <div class="additional-reading">
                        <h4>Additional Readings</h4>
                        <a href="https://www.afsc.org/blogs/news-and-commentary/reimagining-community-safety" target="_blank">Reimagining community safety</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                        <a href="https://morrisjustice.org/community-safety-wall/" target="_blank">Community Safety Wall | Morris Justice: A Public Science Project</a>&nbsp;&nbsp;&#8594;<br />
                        <a href="https://wardmuseum.ca/blockbyblock/#exhibition" target="_blank">Block By Block | Toronto Ward Museum </a>&nbsp;&nbsp;&#8594;<br />
                        <a href="https://www.youtube.com/watch?v=_Cib5A7gf90" target="_blank">Beyond Block by Block | In Conversation: Community Care Across Toronto | Toronto Ward Museum</a>&nbsp;&nbsp;&#8594;<br />
                    </div>
                    <Reflection text={
                        <>
                        <ol>
                            <li>Refer to the concrete alternatives offer in Reimagining community safety. What do you think of them? What would you add to them? </li> 
                            <li>What do the acts in the “Acts of Community” graphic mean to you? What is community care? How do you practice it?</li>
                            <li>How has policing informed your conception of safety? How does this differ from community safety? </li>
                            <li>Think about a time when you felt safe. What were you doing? Who was with you?</li>
                            <li>How do you keep your community safe?</li>
                            <li>In light of prior learnings, why do you think policing and community safety are different?</li>
                            <li>What does community safety mean to you? 
                            <ReflectionForm id="WhatDoesCommunitySafetyMeanToYou"/>
                            </li>
                        </ol>
                        </>
                    } />
                    <p>Community safety is not a new concept to the Tamil community in Toronto. Over the years, we have forged networks of support, coming together to care for each other. </p>
                    </div>
                    <div id="CanYTD" class="text-container-md">
                        <h2 class="header">CanYTD</h2>
                        <p class="text">In <a href="https://www.toronto.com/opinion-story/8773740-canadian-tamil-youth-development-centre-marks-20-years-of-empowerment/" target="_blank">response</a> to the criminalization of Tamil youth in the late 1990s and early 2000s, manifest in the Toronto Police’s Tamil Task Force and Project 1050, the <a href="http://cantyd.org/about/">Canadian Tamil Youth Development Centre</a> was founded by Tamil community members. Driven by a mission of “empowering and developing Tamil youth”, CanTYD <a href="http://162.243.233.130/toronto-organization-played-critical-role-reducing-tamil-youth-violence-90s/">embodies</a> the principles underlying the Defund the Police movement. Providing continued community support, CanTYD exemplifies how safe communities are created by dealing with the causes of harm, not by punishment and surveillance.</p>
                        <Reflection text={
                        <>
                        <ol>
                            <li>Can you think of any other examples of how community safety (outside of the carceral system) has been practiced in the Tamil community? 
                            <ReflectionForm id="CanYouThinkOfAnyOtherExamplesOfHow"/>
                            </li>
                        </ol>
                        </>
                    } />
                    </div>
                    <div id="Violence" class="text-container-md">
                        <h2 class="header">Interpersonal Violence</h2>
                        <p class="text">Gender-based, intimate partner violence is pervasive within the Tamil community, stemming from deep-seated misogyny and trauma.<br/><br/>
                        As Mirusha Yogarajah <a href="https://tamilculture.com/our-women-deserve-more" target="_blank">explains</a>, “there is violent misogyny in the Tamil community that is perpetuated across various mediums—from movies that normalize the act of stalking like Sindhubaadh, the devaluing of women’s work in professional and artistic spaces, the pervasive homophobia and transphobia in the community, and unrelenting alcoholism amongst Tamil men...Misogyny is deeply rooted in unresolved trauma and the desire to practice control over someone else. This unresolved trauma for Tamil men may be because they were victims of abuse at the hands of relatives, they witnessed the war in Sri Lanka, or they experienced excessive taunting and bullying navigating traditionally racist institutions. If this trauma continues to go unresolved, it will only contribute to a cycle of violence within their families, and their children’s families, and so on.”<br/><br/>
                        Increased policing, however, has proven not to create safety for women and women-identifying folks. First, the police are reactionary; they do not prevent harm, but respond to it. Even when they do respond, we <a href="https://thetyee.ca/Analysis/2020/08/26/Women-Safer-Defund-Police/">cannot assume</a> that they provide protection (especially for racialized women). Instead, we must dismantle the systems and norms that enable interpersonal violence in the first place. In the Tamil community, there is a lot of hard work to be done, but it is necessary work. (Watch this <a href="https://iseeinitiative.wixsite.com/iseeinitiative/media">video</a> featuring Dear Tamil Men and the ISEE Initiative discussing why we must address misogyny within the Tamil community if we want to prevent interpersonal violence.)<br/><br/>
                        Indeed, understanding that stemming interpersonal violence does not come from increasing reliance on policing, but rather doing the necessary work within our community, Tamil community members have come together and set up initiatives like the <a href="https://kudai.ca/">Kudai Centre</a>, <a href="https://www.instagram.com/deartamilmen/?hl=en">Dear Tamil Men</a>, and the <a href="https://l.facebook.com/l.php?u=http%3A%2F%2Fiseeinitiative.com%2F%3Ffbclid%3DIwAR1XE6oifQTeqJJpkKt-fGvxs1trfSJqjY9k8NCv_p_xKYCddA9j4M_afck&h=AT3FGEvZuRTgbc8BysyJVAT-5wlvuCLiYIEnRNZxLqhCo6pUEtO2rHA701ArThjkjQoCv8MlkBPIDWAniywuCCrGYnVLTSO3UbMpLwg__yAt3wwgXZB6b1LsGUFDUg05-OgKyIc9X28">ISEE Initiative</a>.
                        </p>
                        <div class="additional-reading">
                            <h4>Additional Readings</h4>
                            <a href="https://thetyee.ca/Analysis/2020/08/26/Women-Safer-Defund-Police/" target="_blank">Want to Make Women Safer? Defund the Police</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://tamilculture.com/our-women-deserve-more" target="_blank">Our Women Deserve More: Addressing Misogyny in the Tamil Community</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.thaenpot.com/thaenx/ipv-in-the-tamil-community" target="_blank">The External and the Interpersonal: IPV in the Tamil Community</a>&nbsp;&nbsp;&#8594;<br />
                            <p>Please explore the graphic entitled “Acts of Community”</p>
                            <a href="https://youtube.com/playlist?list=PLZVvcIc5ImZQVw3OLLlEPKO7rCADQpWQf" target="_blank">Addressing Gender-Based Violence - Tamil Discussion Series</a>&nbsp;&nbsp;&#8594;<br />
                        </div>
                    </div>
                    <div id="Defund" class="text-container-md">
                        <h2 class="header">Defund the Police</h2>
                        <p>For decades, there have been calls to defund the police and reallocate resources to real community safety. In 2020, the movement saw a resurgence that took it to new heights. “Defunding the police” looks different in every context, but the underlying concept is divesting funds from policing and redirecting them into housing, education, healthcare, and other forms of social or community support.</p>
                        <div class="additional-reading">
                            <h4>Additional Readings</h4>
                            <a href="https://youtu.be/yMcwklHOyYU" target="_blank">“Defund the police,” explained</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.fastcompany.com/90514184/these-posters-help-you-picture-alternatives-to-policing" target="_blank">These posters can help you picture alternatives to policing | Fast Company</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://youtu.be/bT0YpOmk8NA" target="_blank">Defund the Police | Project NIA</a>&nbsp;&nbsp;&#8594;<br />
                            <p>Please explore the graphic entitled “Acts of Community”</p>
                            <a href="https://defund.ca/" target="_blank">Defund.ca</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.chatelaine.com/opinion/defund-the-police-2/" target="_blank">What Would It Mean To Defund The Police? | Sandy Hudson</a>&nbsp;&nbsp;&#8594;<br />
                        </div>
                        <Activity
                        text={
                            <>
                            <p>What do police really do? [developed by Reclaim the Block/Black Visions] and found in <a href="https://static1.squarespace.com/static/5ee39ec764dbd7179cf1243c/t/5f866236dcff487723fa0fa8/1602642486099/Defund+Police+Discussion+Guide.pdf">Discussion Questions and Prompts by Project NIA</a>. The activity has been reproduced here for convenience, but all credit belongs to Reclaim the Block/Black Visions.</p> 
                            <p>Draw a police station in the center on a piece of paper. Around the police station, draw all the places you see police in your community (at schools, parks, etc…)</p> 
                            <p>Look at your paper and put an X everywhere you believe that we can get rid of police today. For example, do you think we need cops with guns at the grocery store, at sporting events, at libraries etc… </p> 
                            <p><i>Q: How many places did you cross off with an X? What does this tell you about policing and what cops do?</i></p> 
                            </>
                        }
                        />
                        <div class="subsection">
                            <h4>Defund the Police (Toronto)</h4>
                            <p>In Toronto, Black and Indigenous Peoples have led movements calling attention to police violence and advocating for reimagining community safety for decades. Indeed,  the Defund the Police movement has deep roots in this city, but it is now seeing unprecedented support. For example, Education Not Incarceration and Black Lives Matter Toronto led the movement that saw police officers removed from Toronto schools (the School Resource Officer program).</p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.youtube.com/watch?v=G81L0T8RySM&t=296s" target="_blank">Defunding the Police: A discussion on reprioritizing city investments as we build a just Toronto</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <li>[00:00 - 06:48]</li>
                                <li>[59:25 -01:00:30]</li><br/>
                                <a href="https://pressprogress.ca/canadians-are-talking-about-defunding-the-police-heres-what-that-means-and-what-it-could-look-like/" target="_blank">Canadians are Talking About ‘Defunding the Police’. Here’s What That Means and What It Could Look Like. | Amira Elghawaby</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.cbc.ca/news/canada/saskatoon/question-answer-sandy-hudson-black-lives-matter-defund-police-1.5613280" target="_blank">Q&A: Founder of Black Lives Matter in Canada explains the call to defund police</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.cbc.ca/news/canada/manitoba/defund-police-mental-health-crisis-intervention-1.5608627" target="_blank">Approach mental health crises with care, not policing: crisis worker</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.facebook.com/ajplusenglish/videos/1105745749566931" target="_blank">Toronto Schools Ban Police on Campuses</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://nowtoronto.com/news/the-toronto-school-board-votes-to-end-cops-in-schools-progra" target="_blank">Toronto school board votes to end cops in schools program, so whats next?</a>&nbsp;&nbsp;&#8594;<br />
                            </div>
                            <p>In Toronto, policing comprises the largest expense in the operating budget; more tax dollars are spent on policing than anything else. As Vijay Prashad states, “The annual budget is a much better reflection of a country's morality than its constitution. You put more money into repression, police, military... and so little to taking care of human troubles.” </p>
                            <img class="mt-4 w-100" src="../assets/tax-dollars-working.png"/>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.youtube.com/watch?v=G81L0T8RySM&t=296s" target="_blank">Defunding the Police: A discussion on reprioritizing city investments as we build a just Toronto</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <li>[49:13 - 52:06]</li><br/>

                                <a href="https://www.socialplanningtoronto.org/2021_city_budget_city_council_passes_1st_covid_budget" target="_blank">"City Budget Matters 101": What You Need to Know About the 2021 City Budget</a>&nbsp;&nbsp;&#8594;<br />
                                <p>(Read section entitled “Municipal Revenues”) </p>

                                <a href="https://www.thestar.com/news/gta/2020/12/15/where-does-the-money-go-how-toronto-police-planned-to-spend-more-than-1-billion-in-2020.html" target="_blank">Where does the money go? How Toronto police planned to spend more than $1 billion in 2020</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.socialplanningtoronto.org/peoples_budget_platform" target="_blank">The 2021 People's Budget Platform</a>&nbsp;&nbsp;&#8594;<br />
                            </div>
                            <Reflection text={
                                <>
                                <ol>
                                    <li>Are you surprised by how much is spent on policing?</li>
                                    <li>Do you want to live in a city where more money is spent on policing, instead of social and community support?</li>
                                    <li>What does a city budget that promotes community safety look like?</li>
                                    <li>Based on the image above, where would you spend more money? Why?</li>
                                    <li>What do you want to see in the City’s 2022 budget? How can community safety be promoted in the 2022 budget?</li>
                                </ol>
                                </>
                            }/>
                            <p>
                            Defunding the police, however, is not solely about reallocating funds. As Robyn Maynard <a href="https://www.youtube.com/watch?v=x04AF3QAOkw&ab_channel=CanadianSociologicalAssociation">explains</a>, “we can think about it as a transformative project as this beautiful care-based way of recreating and re-giving a new meaning to the word of safety in our society that moves away from the idea that police (and prisons more broadly) are the thing that keeps us safe…What I see the defunding movement being is actually about the creation of a real felt kind of safety and security in our society.”<br/><br/>

                            In this sense, it is also about reducing the scope of policing and punishment. The Canadian legal system has a long history of using the criminal law to oppress, harm, and discriminate against marginalized and vulnerable populations. For example, the Canadian state often criminalized Indigenous Peoples’ cultural practices, including the decades-long, <a href="https://umistapotlatch.ca/potlatch_interdire-potlatch_ban-eng.php">Potlatch ban</a>. Additionally, so-called “quality of life” offences punish people for using public space for basic life necessities (such as sleeping). <br/><br/>

                            Indeed, the City of Toronto continues to criminalize poverty, as most recently demonstrated by the encampment evictions of the summer of 2021. These evictions cost the City of Toronto <a href="https://globalnews.ca/news/8199534/city-of-toronto-homeless-encampment-clearing-costs/">over 2 million dollars</a>. Arguably, these funds would have been better spent on securing housing and community support for one of the city’s most vulnerable and overlooked populations. Indeed, <a href="https://canadiandimension.com/articles/view/hounding-torontos-homeless">as John Clarke writes,</a> “[t]he neoliberal city needs its enforcers as a matter of great priority. Those who are denied the right of housing must not be allowed to become too visible. If they seek shelter and safety in public parks, they will soon learn that, while there may be no housing or even adequate shelter available for them, there will be no lack of police batons to drive them from view.” <br/><br/>
                            
                            All things considered, the Defund the Police movement asserts that better futures are possible; that we can create a society founded on care, not punishment. We can begin by divesting from systems of harm and investing in life-affirming institutions that provide real safety
                            </p>
                        </div>
                        <div class="additional-reading">
                            <h4>Additional Readings</h4>
                            <a href="https://www.aljazeera.com/opinions/2021/7/20/encampment-evictions-another-face-of-colonial-violence-in-canada" target="_blank">'Encampment evictions: Another face of colonial violence in Canada </a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://ricochet.media/en/3175/defund-the-police-not-just-to-end-police-violence-but-to-end-violence-itself" target="_blank">Defund the police, not just to end police violence, but to end violence itself </a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://drugpolicy.ca/decriminalizing-drugs-and-the-path-towards-defunding-police/" target="_blank">Decriminalizing drugs and the path towards defunding police </a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://ccla.org/our-work/criminal-justice/decriminalization-defunding-detasking/" target="_blank">Decriminalization, Defunding & Detasking | Canadian Civil Liberties Association </a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://youtu.be/x04AF3QAOkw" target="_blank">Racialized policing in Canada and the drive to defund police | Canadian Sociological Association</a>&nbsp;&nbsp;&#8594;<br />
                    </div>
                    </div> 
                    <div id="Alternatives" class="text-container-md">
                        <h2 class="header">The Alternatives: Current Initiatives and Efforts in Toronto</h2>
                        <p>Before exploring alternatives, it is necessary to understand why experimentation is so important. Indeed, some questions may naturally present themselves—what are the alternatives? how would they work? are they feasible?—and understandably so, our paradigms have been heavily informed by punitive responses. However, as Garrett Felber states, “Ideas do not arrive fully formed, and movements are not linear, but these histories of struggle provide raw material for us to build a world without gendered racial terror.”</p>
                        <div class="initial-reading">
                            <h4>Initial Reading</h4>
                            <a href="https://static1.squarespace.com/static/6017561aa0646e0baa91251c/t/60257ae4703af042c3f2dd3e/1613069033514/NationalDefundingDocument_FINAL+%285%29.pdf" target="_blank">National Defunding Document | Robyn Maynard&nbsp;&nbsp;&#8594;</a>
                        </div>
                        <p>In the City of Toronto, there are a variety of organizations furthering the Defund the Police movement. While you are reading their proposals and efforts, think about what resonates with you—this may be a launching point for how you engage with the movement. </p>
                        <table class="cta">
                            <tr>
                                <td class="svg-holder"><svg class="circle-svg" xmlns="http://www.w3.org/2000/svg" version="1.1"><circle cx="17" cy="20" r="10" stroke="var(--accent)" stroke-width="8" fill="none" /></svg> </td>
                                <td>
                                    <a href="https://mcusercontent.com/de85a14a3dcadd8e377462ff6/files/1acb8a31-d2e9-464a-b826-d024cb61ed6f/Rethinking_Community_Safety_A_Step_Forward_For_Toronto_Full_Report.pdf" target="_blank">Rethinking Community Safety: A Step Forward For Toronto</a>
                                    <p>(January 2021). A summary of the report and an interview with Chantelle Krupka can be accessed</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="svg-holder"><svg class="circle-svg" xmlns="http://www.w3.org/2000/svg" version="1.1"><circle cx="17" cy="20" r="10" stroke="var(--accent-pink)" stroke-width="8" fill="none" /></svg> </td>
                                <td>
                                    <a href="http://doctorsfordefundingpolice.com/" target="_blank">Policing is a Public Health Crisis | Doctors for Defunding the Police</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="svg-holder"><svg class="circle-svg" xmlns="http://www.w3.org/2000/svg" version="1.1"><circle cx="17" cy="20" r="10" stroke="var(--accent-orange)" stroke-width="8" fill="none" /></svg> </td>
                                <td>
                                    <a href="https://www.instagram.com/p/CBtYT-qpoxh/" target="_blank">Demands | Black Lives Matter Toronto</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="svg-holder"><svg class="circle-svg" xmlns="http://www.w3.org/2000/svg" version="1.1"><circle cx="17" cy="20" r="10" stroke="var(--accent-purple)" stroke-width="8" fill="none" /></svg> </td>
                                <td>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhGAsyW1_naWEGrOH9xqf98BjCxFvwIvNCSoANI_bfE6m5vw/viewform" target="_blank">Choosing Real Safety: A Historic Declaration to Divest from Policing and Prisons and Build Safer Communities For All</a>
                                </td>
                            </tr>
                        </table>
                        <div class="subsection">
                            <h4>City of Toronto Initiatives</h4>
                            <ul class="initiatives">
                                <li><a href="https://www.toronto.ca/community-people/public-safety-alerts/community-safety-programs/community-crisis-response-program/">Community Crisis Response Program (CCRP)&nbsp;&nbsp;&#8594;</a></li>
                                <li><a href="https://www.toronto.ca/community-people/public-safety-alerts/community-safety-programs/community-safety-well-being-plan/">SafeTO: A Community Safety & Well-Being Plan&nbsp;&nbsp;&#8594;</a></li>
                                <li><a href="https://www.toronto.ca/community-people/public-safety-alerts/community-safety-programs/focus-toronto/">FOCUS Toronto</a></li>
                                <li><a href="https://www.toronto.ca/community-people/public-safety-alerts/community-safety-programs/community-healing-project/">Community Healing Project&nbsp;&nbsp;&#8594;</a></li>
                                <li><a href="https://www.toronto.ca/community-people/public-safety-alerts/community-safety-programs/spider/">Specialized Program for Inter-Divisional Enhanced Responsiveness to Vulnerability (SPIDER)&nbsp;&nbsp;&#8594;</a></li>
                                <li><a href="https://www.toronto.ca/community-people/public-safety-alerts/community-safety-programs/youth-violence-prevention-intervention/">Youth Violence Prevention & Intervention&nbsp;&nbsp;&#8594;</a></li>
                            </ul>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.cbc.ca/news/canada/toronto/toronto-policing-alternative-pilot-1.6083164" target="_blank">'We've got to do better': Toronto readies to launch non-police crisis response pilot </a>&nbsp;&nbsp;&#8594;<br />
                            </div>
                            <Reflection text={
                                <>
                                <ol>
                                    <li>How do these initiatives differ from the calls to action by community members and organizations?</li>
                                    <li>What do you think of these initiatives? What do you like about them? What do you dislike about them?</li>
                                    <li>How would you change these initiatives? What are their limitations? </li>
                                    <li>What would you establish to further community safety in the City of Toronto? 
                                    <ReflectionForm id="WhatWouldYouEstablishToFurtherCommunitySafety"/>
                                    </li>
                                </ol>
                                </>
                            }/>
                            <p>It is imperative to understand that the Defund the Police movement extends beyond policing and the criminal legal system. As we have learned, reimagining community safety requires investments in education, housing, mental health, harm reduction, crisis intervention, community spaces, etc. As such, we must also explore how the City of Toronto addresses these issues as well. </p>
                            <Activity text={
                                <>
                                <p>Explore programs and initiatives established by the City of Toronto. Take your time and play around with the website. Let yourself get lost. This activity is intended to help you become familiarized with what the City of Toronto is currently doing.</p>
                                <p>Starting points:</p>
                                <a href="https://www.toronto.ca/community-people/health-wellness-care/">Health & Wellness</a><br/>
                                <a href="https://www.toronto.ca/community-people/housing-shelter/">Housing & Shelter</a><br/>
                                <a href="https://www.toronto.ca/community-people/employment-social-support/">Financial & Employment Support</a><br/>
                                <a href="https://www.toronto.ca/community-people/community-partners/">Community Partners</a><br/>
                                <a href="https://www.toronto.ca/community-people/children-parenting/">Children, Youth, Parents & Seniors</a>
                                </>
                            }
                            />
                            <Reflection text={
                                <>
                                <ol>
                                    <li>What did you learn?</li>
                                    <li>What initiatives resonated with you? Which ones did not?</li>
                                    <li>Which initiatives further the Defund the Police movement? Why or why not? </li>
                                    <li>If you could allocate some money from policing into some of these initiatives, which ones would you choose? </li>
                                    <li>How do these initiatives differ from the calls to action by community members and organizations?</li>
                                    <li>What do you think is missing? Why?</li>
                                </ol>
                                </>
                            }/>
                            <Activity text={
                            <>
                            <p>Look at the <a href="https://www.toronto.ca/community-people/get-involved/public-consultations/">list of public consultations</a> currently being conducted by the City of Toronto. Which ones do you think are relevant to the Defund the Police movement and reimagining community safety? What would you say at this consultation? Note down your points, make a plan to attend, and bring a friend!</p>
                            </>
                            }/>
                            <Activity text={
                                <>
                                <p>From <a href="https://urbanomnibus.net/2022/01/care-where/?fbclid=IwAR3KZlA-Y3a6Vx9RFvfQ9Tu6SGi_Lg4FQSkcGB_Uoe3Fp_5-UFSESfAP5Z4">Care, Where?</a> :</p>
                                <p>“A sustained moment of reckoning has also brought opportunities to reimagine the public realm in ways large and small. The last two years have seen a proliferation of experiments and proposals, from open streets and streeteries, to a new NYC Streets Plan, to calls for a new Deputy Mayor for Placemaking and the Public Realm . . . or a new city department dedicated to the maintenance and care of our public spaces. A Department of Care, as Justin Garrett Moore proposes, would ‘strengthen local capacity to reimagine, maintain, and care for public spaces,’ and address unequal amenities and geographies. Maintenance and care are having a moment in the built environment, but what they really demand is a long-term commitment.”</p>
                                <p><b>What could a Department of Care look like in the City of Toronto? What parts of the city and which populations are not cared for?</b></p>
                                </>
                            }/>
                        </div>
                    </div>
                    <div id="Action" class="text-container-md">
                        <h2 class="header">Taking Action</h2>
                        <div class="quote-small-holder">
                            <h2 class="quote-small-left">“The Tamil shutdown of the Gardiner, Idle No More, and Black Lives Matter laid a foundation for me that has translated to every place in the world I have been since. Freedom fighting is a universal language, and I am humbled by the ferocity of my team and of those that influenced and continue to influence us.”</h2>
                            <h3 class="author">Janaya Khan</h3>
                        </div>
                        <p class="text">
                            Understanding existing systems is a prerequisite to changing them, but study must necessarily be paired with struggle. In this final section, we will explore some ways to work towards transnational and multiracial solidarity in the context of the Defund the Police movement. 
                        </p>
                        <div class="subsection">
                            <h4>Community Organizing</h4>
                            <p>Community organizing has deep roots in the Tamil community in Toronto; since our arrival here, we have engaged in various organizing and community-building efforts. In Toronto, there are various grassroots organizations advancing the Defund the Police movement. <br/><br/>
                            Some organizations/groups are: <br/>
                            <Row class="row">
                                <Col id="card-col-2"><Card name="Showing Up for Racial Justice (SURJ) Toronto" link="https://surjtoronto.com/"/></Col>
                                <Col id="card-col-2"><Card name="No Pride in Policing Coalition (Toronto)" link="https://www.noprideinpolicing.ca/"/></Col>
                                <Col id="card-col-2"><Card name="Toronto Prisoners’ Rights Project" link="https://www.torontoprisonersrightsproject.org/"/></Col>   
                                <Col id="card-col-2"><Card name="No Pride in Policing Coalition (Toronto)" link="https://www.noprideinpolicing.ca/"/></Col>
                                <Col id="card-col-2"><Card name="Doctors for Defunding Police" link="http://doctorsfordefundingpolice.com/about/"/></Col>                         
                            </Row>
                             <Row class="row">
                                
                            </Row>
                            </p> 
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://thewalrus.ca/social-media-is-revolutionizing-the-way-we-protest/" target="_blank">What Black Lives Matter Taught Me about Protesting | Janaya Khan</a>&nbsp;&nbsp;&#8594;<br />
                            </div>
                        </div>
                        <div class="subsection">
                            <h4>Support Community Organizations Promoting Real Safety</h4>
                            <p>As noted above, defunding the police means reinvesting in real community safety. Therefore, it is also important to support organizations doing the necessary front-line work. Indeed, within our existing social infrastructure, many underserved populations are not afforded real safety. For example, in the article <a href="https://canadiandimension.com/articles/view/defund-the-police-means-re-fund-the-community">‘Defund the police’</a> means re-fund the community, the authors offer 2 examples from Winnipeg:</p>
                            <ol class="initiatives">
                                <li><p>“...a coalition of Indigenous-led volunteer organizations (led by Anishinative) have erected tipis at Thunderbird House to help provide warmth, shelter, and necessities to the unhoused during the city’s harsh winter weather. With an army of volunteers and online fundraising, they have provided safety in a way that policing fails to do.”</p></li>
                                <li><p>“Workers at harm-reduction centres like Sunshine House, an inclusive, community drop-in and resource centre, also meet people where they are at, without judgement or screening for ‘deserving’ people in need.”</p></li>
                            </ol>
                            <p>Additionally, by providing alternate modes of community safety, these organizations also provide lessons on how we can build a world founded on care, not punishment. Some organizations in Toronto are:</p>
                            <ul class="initiatives">
                                <li><a href="https://jfaap.wordpress.com/">Jane Finch Action Against Poverty&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="https://www.maggiesto.org/">Maggie’s Toronto&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="https://www.facebook.com/justice4mw/">Justicia for Migrant Workers&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="http://toronto.nooneisillegal.org/">No One is Illegal (Toronto)&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="https://www.instagram.com/unitykitchento/?hl=en">Unity Kitchen&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="https://www.sanctuarytoronto.org/">Sanctuary Toronto&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="https://www.torontoindigenoushr.com/">Toronto Indigenous Harm Reduction&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="https://www.encampmentsupportnetwork.com/">Encampment Support Network&nbsp;&nbsp;&#8594; </a></li>
                                <li><a href="https://www.djno.ca/">Disability Justice Network of Ontario&nbsp;&nbsp;&#8594; </a></li>
                            </ul>
                        </div>
                        <div class="subsection">
                            <h4>Who is your community?</h4>
                            <p>Defunding the police is a transformative project that ultimately requires reimagining how we exist in relation to one another. To shift to a paradigm of care—not punishment—we also have to look within ourselves and our relationships. For example, it is useful to identify how carceral logics permeate our interpersonal relationships. We must build community and resist individualism.</p>
                        </div>
                        <Reflection text={
                            <>
                            <ol>
                                <li>Who are you in community with?</li>
                                <li>Do you know your neighbours? </li>
                                <li>How do carceral logics play out within your daily life? (For example, when a family member says something hurtful, are you tempted to give them the cold shoulder instead of engaging in a dialogue?)</li>
                                <li>Mariame Kaba writes “hope is a discipline.” What does this mean to you?</li>
                            </ol>
                            </>
                        }/>
                    </div>
                  </div>
              </td>
          </tr>
      </table>
    </div>
  );
}