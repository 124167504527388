import './ReflectionForm.css';
import React, { useRef } from "react";

export default function ReflectionForm(props) {
    return (
        <form class="gform" method="POST" data-email="buildingotrumai@gmail.com" action="https://script.google.com/macros/s/AKfycbybU_r47vwz_BLfybYvqkWhErpxPAIaZFfjh4sWw36nHr7OEt-oRyeY75Z0O5ECTTQJtA/exec">
            <fiedlset class="pure-group">
                <textarea name={props.id} class="form-control textArea" id={props.id} rows="4"></textarea>
                <div style={{display: 'none'}} class="thankyou_message">
                    <b><p>Thanks for your submission</p></b>
                </div>
                <button type="submit" class="btn textButton">Submit</button>
            </fiedlset>
        </form>
    ); 
}
