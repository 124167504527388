import './Policing.css';

import React, { useRef, useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col} from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Card from "../components/Card";
import Reflection from "../components/Reflection";
import ThemeNav from './ThemesNav';

export default function Theme1() {
  return (
    <div class="theme1">
      <table>
          <tr class="first-row">
              <td class="left-panel">
                <ThemeNav />
                <div>
                    <h3 class="page-name" id="PolicingPage">Policing in Sri Lanka and Canada</h3>
                    <a href="#About"><h5>About</h5></a>
                    <a href="#Genocide"><h5>Tamil Genocide and the Police</h5></a>
                    <a href="#Indigenous"><h5>Indigenous Genocide</h5></a>
                    <a href="#Racism"><h5>Anti-Black Racism</h5></a>
                    <a href="#Policing"><h5>Policing of Tamils in Canada</h5></a>
                </div>
                <div class="d-flex justify-content-between">
                </div>
              </td>
              <td class="right-panel" id="right-panel">
                  <div class="container-holder">
                    <div id="About" class="text-container-md">
                        <h2  class="header">About</h2>
                        <p class="text">
                        The previous section sought to afford nuance to the Toronto Tamil identity and assume agency over its narrative, thereby resisting its co-optation for partisan agendas. In assuming agency over the Tamil identity, we can also further our resistance and solidarity efforts. As such, the next two sections will explore how solidarity can be forged with the Defund the Police movement* in Toronto. <br/><br/>

                        To understand the Defund the Police movement, we must first problematize policing. In Canada and Sri Lanka, the police act as agents of the state; they enforce the laws of the state and protect the social order preferential to the state. In Sri Lanka, this social order is Tamil genocide. In Canada, it is founded on Indigenous genocide and anti-Black racism. <br/><br/>

                        It is important to note that information shared in this section is—and always will be—incomplete. Oppressed and/or criminalized people seldom have the ability to record their oppression. As such, the records often create and perpetuate a specific narrative, crafted by those in power. <br/><br/>

                        *While the Defund the Police movements is the focus of this resource, multi-racial and transnational solidary can—and must—be accorded to various movements. <br/><br/>
                        </p>
                    </div>  
                    <div id="Genocide" class="text-container-md">
                        <h2 class="header">Tamil Genocide and the Police</h2>
                        <div class="quote-small-holder">
                            <h2 class="quote-small">“In uncovering our history, we possess the ability to safeguard our truths and more intimately understand our struggle.”</h2>
                            <h3 class="author">Brannavy Jeyasundaram</h3>
                            <h3 class="author explain">Beneath the Ashes: Remembering Black July and the Violence Before</h3>
                        </div>
                        <div class="initial-reading">
                            <h4>Initial Reading</h4>
                            <a href="https://www.tamilguardian.com/content/defunding-and-demilitarising-sri-lanka" target="_blank">Defunding and demilitarising in Sri Lanka | Tamil Guardian&nbsp;&nbsp;&#8594;</a>
                        </div>
                        <Row class="row" id="card-row">
                            <Col id="card-col-2"><Card name="Tamil Genocide | People for Equality and Relief in Lanka" link="https://pearlaction.org/tamil-genocide/"/></Col>
                            <Col id="card-col-2"><Card name="A Decade Without Justice for Sri Lanka’s Tamils | Anjali Manivannan" link="https://dviyer.medium.com/south-asians-and-black-lives-b30adaba6a42"/></Col>
                            <Col id="card-col-2"><Card name="Tamil Resistance & Resilience in the Face of Genocide | People for Equality and Relief in Lanka" link="https://pearlaction.org/inthefaceofgenocide/#dxhspnbnanuriffclzvyvsinlgnqhxycsvrk"/></Col>
                        </Row>
                        <p>
                        In Sri Lanka, the genocide of Tamils extends beyond the violence of its 30-year war, and is manifest in its laws, institutions, and norms, as well. To enforce this, Sri Lanka requires a large security infrastructure, namely the police and army. 
                        </p>
                        <div class="subsection">
                        <h4>Prevention of Terrorism Act (PTA) </h4>
                        <p>The infamous Prevention of Terrorism Act was enacted temporarily in 1979, and then made permanent in 1982. It affords the police (and other government agents) the power to arbitrarily search, arrest, and detain individuals suspected of engaging in unlawful activities. Importantly, what constitutes “unlawful activities” continues to remains unclear. Consequently, it created a culture of impunity, enabling the police to exercise virtually unchecked power over minority populations that still exists today. </p>
                        <div class="additional-reading">
                            <h4>Additional Readings</h4>
                            <a href="https://www.hrw.org/report/2018/01/29/locked-without-evidence/abuses-under-sri-lankas-prevention-terrorism-act" target="_blank">Locked Up Without Evidence | Human Rights Watch</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://twitter.com/ambikasat/status/1442859757769949187" target="_blank">Twitter Thread by Ambika Satkunanathan </a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.tamilguardian.com/content/beneath-ashes-remembering-black-july-and-violence-%C2%A0" target="_blank">Beneath the Ashes: Remembering Black July and the Violence Before | Tamil Guardian</a>&nbsp;&nbsp;&nbsp;&#8594;<br/>
                            <a href="https://lstlanka.org/images/publications/reports/2020/PTA_Terrorising-Sri-Lanka-for-42-years-English.pdf" target="_blank">Creating National (In)security: Prevention of Terrorism Act and the Death Penalty PTA: Terrorising Sri Lanka for 42 years | Marisa De Silva</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                        </div>
                        {/* <Row>
                            <Col id="card-col-2"><Card name="Searching for anti-racism agendas in South Asian Canadian communities" link="https://theconversation.com/searching-for-anti-racism-agendas-in-south-asian-canadian-communities-142431"/></Col>
                            <Col id="card-col-2"><Card name="South Asians and Black Lives (Tamil Translation)" link="https://dviyer.medium.com/south-asians-and-black-lives-b30adaba6a42"/></Col>
                            <Col id="card-col-2"><Card name="Letters for Black Lives (Tamil Translation)" link="https://lettersforblacklives.com/%E0%AE%85%E0%AE%A9%E0%AF%8D%E0%AE%AA%E0%AF%81%E0%AE%B3%E0%AF%8D%E0%AE%B3-%E0%AE%85%E0%AE%AE%E0%AF%8D%E0%AE%AE%E0%AE%BE-%E0%AE%85%E0%AE%AA%E0%AF%8D%E0%AE%AA%E0%AE%BE-%E0%AE%AE%E0%AE%BE%E0%AE%AE%E0%AE%BE-%E0%AE%85%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AF%88-%E0%AE%A4%E0%AE%BE%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AE%BE-%E0%AE%AA%E0%AE%BE%E0%AE%9F%E0%AF%8D%E0%AE%9F%E0%AE%BF-%E0%AE%95%E0%AF%81%E0%AE%9F%E0%AF%81%E0%AE%AE%E0%AF%8D%E0%AE%AA%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AE%BF%E0%AE%A9%E0%AE%B0%E0%AF%8D-%E0%AE%AE%E0%AE%B1%E0%AF%8D%E0%AE%B1%E0%AF%81%E0%AE%AE%E0%AF%8D-%E0%AE%A8%E0%AE%A3%E0%AF%8D%E0%AE%AA%E0%AE%B0%E0%AF%8D%E0%AE%95%E0%AE%B3%E0%AF%87-77f62e2a9dc0"/></Col>
                            <Col id="card-col-2"><Card name="It’s Time to Confront Anti-Blackness within the South Asian Diaspora" link="https://tamilculture.com/confronting-anti-blackness-within-the-south-asian-diaspora"/></Col>
                        </Row> */}
                        </div>

                        <div class="subsection">
                            <h4>Anti-Tamil Pogroms and Black July</h4>
                            <p>Anti-Tamil pogroms were some of the earliest, and most brutal, forms of violence against Tamils. The state-sanctioned violence was largely conducted by Sinhalese civilians, and the police were reported to have either stood idly by, engaged in, or even incited the violence themselves. Anti-Tamil pogroms occurred in 1956, 1958 1977, and 1983. The latter is often referred to as Black July, for its unprecedented violence. In fact, Black July is cited as the start of the decades-long civil war, and the catalyst of the mass exodus of Tamils in Sri Lanka. </p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.tamilguardian.com/content/remembering-1956-sri-lanka-s-first-anti-tamil-pogrom" target="_blank">Remembering 1956 – Sri Lanka’s first Anti-Tamil pogrom | Tamil Guardian</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.sciencespo.fr/mass-violence-war-massacre-resistance/en/document/massacres-sri-lanka-during-black-july-riots-1983.html" target="_blank">The Massacres in Sri Lanka During the Black July Riots of 1983 | Sciences Po</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="http://www.humanrights.asia/news/ahrc-news/AS-20-2004/" target="_blank">SRI LANKA: Facing up to the dark legacy of state-sponsored riots | Asian Human Rights Commission </a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://pearlaction.org/rememberingblackjuly/" target="_blank">Black July: A Tamil Genocide | People for Equality and Relief in Lanka</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            </div>
                            {/* <Row>
                            <Col id="card-col-2"><Card name="Searching for anti-racism agendas in South Asian Canadian communities" link="https://theconversation.com/searching-for-anti-racism-agendas-in-south-asian-canadian-communities-142431"/></Col>
                            <Col id="card-col-2"><Card name="South Asians and Black Lives (Tamil Translation)" link="https://dviyer.medium.com/south-asians-and-black-lives-b30adaba6a42"/></Col>
                            <Col id="card-col-2"><Card name="Letters for Black Lives (Tamil Translation)" link="https://lettersforblacklives.com/%E0%AE%85%E0%AE%A9%E0%AF%8D%E0%AE%AA%E0%AF%81%E0%AE%B3%E0%AF%8D%E0%AE%B3-%E0%AE%85%E0%AE%AE%E0%AF%8D%E0%AE%AE%E0%AE%BE-%E0%AE%85%E0%AE%AA%E0%AF%8D%E0%AE%AA%E0%AE%BE-%E0%AE%AE%E0%AE%BE%E0%AE%AE%E0%AE%BE-%E0%AE%85%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AF%88-%E0%AE%A4%E0%AE%BE%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AE%BE-%E0%AE%AA%E0%AE%BE%E0%AE%9F%E0%AF%8D%E0%AE%9F%E0%AE%BF-%E0%AE%95%E0%AF%81%E0%AE%9F%E0%AF%81%E0%AE%AE%E0%AF%8D%E0%AE%AA%E0%AE%A4%E0%AF%8D%E0%AE%A4%E0%AE%BF%E0%AE%A9%E0%AE%B0%E0%AF%8D-%E0%AE%AE%E0%AE%B1%E0%AF%8D%E0%AE%B1%E0%AF%81%E0%AE%AE%E0%AF%8D-%E0%AE%A8%E0%AE%A3%E0%AF%8D%E0%AE%AA%E0%AE%B0%E0%AF%8D%E0%AE%95%E0%AE%B3%E0%AF%87-77f62e2a9dc0"/></Col>
                            <Col id="card-col-2"><Card name="It’s Time to Confront Anti-Blackness within the South Asian Diaspora" link="https://tamilculture.com/confronting-anti-blackness-within-the-south-asian-diaspora"/></Col>
                        </Row> */}
                        </div>
                        <div class="subsection">
                            <h4>The Burning of the Jaffna Public Library</h4>
                            <p>In response to the fatal shooting of 3 Sinhalese police officers at a Tamil United Liberation Front (TULF) rally, Sinhalese police officers and “mobs” set Tamil stores, organizations, homes, and most prominently, the Jaffna Public Library, on fire. Indeed, the library was one of the largest of its kind in Asia, housing over 95, 000 irreplaceable texts, including those written on palm leaves.</p>
                            <Row>
                                <Col id="card-col-2"><Card name="Destruction of Jaffna Public Library - 1981 | Tamil Canadian" link="https://theconversation.com/searching-for-anti-racism-agendas-in-south-asian-canadian-communities-142431"/></Col>
                                <Col id="card-col-2"><Card name="The Library Which Sparked a Civil War in Sri Lanka | Journeyman Pictures" link="https://youtu.be/cT6z8irTXHg"/></Col>
                                <Col id="card-col-2"><Card name="History in flames: remembering the burning of Jaffna Library | Tamil Guardian" link="https://www.tamilguardian.com/content/history-flames-remembering-burning-jaffna-library-0"/></Col>
                                <Col id="card-col-2"><Card name="Jaffna Library Burning | Canadian Tamil Congress" link="https://www.jaffnalibraryburning.com/"/></Col>
                            </Row>
                        </div>
                        <div class="subsection">
                            <h4>During the War</h4>
                            <table class="war-table">
                                <tr>
                                    <td>
                                        <p>It is not possible to outline the entirety of the violence inflicted by the police during the decades-long war. They played various roles, and often worked in tandem with the Sri Lankan army. Indeed, the structure of the Sri Lankan security infrastructure often obscures some of the differences between the police and the army. Documenting police violence during the war is also more difficult given the lack of media presence allowed in Sri Lanka at the time.<br/><br/>
                                        For brevity, this resource will not focus too heavily on the role of the police during the war. In an effort to cease training of Sri Lankan police by British officers, the ITJP outlined some of the egregious police violence during and after the war.
                                        </p>
                                        <div class="read-more"><a href="https://www.tamilguardian.com/sites/default/files/Image/pictures/2021/KNDU%20Bill/13th%20August%20press%20release%20final%202021.pdf" target="_blank"><p>Learn More</p><span class="read-more-arrow">&nbsp;&nbsp;&#8594;</span></a></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="subsection">
                        <h4>2009 Onwards</h4>
                        <p>The life of Tamil people in Sri Lanka remains vehemently surveilled in Sri Lanka. In fact, policing in the Northern province (where Tamils primarily reside) is conducted primarily by officers who do not speak Tamil. Specifically, in 2019, it was reported that <a class="link-color" href="https://www.tamilguardian.com/content/only-12-norths-police-officers-speak-tamil">only 12%</a> of officers could speak Tamil. <br /><br />

                        Since the war, the police have continued to occupy and permanently seize privately-owned land. For example, in 2019, the <a href="https://www.tamilguardian.com/content/sri-lanka-police-initiate-permanent-land-grab-manipay" target="_blank" class="link-color">Manipay Police</a> permanently seize privately-owned land for their operations. The military and the police also assist the Sinhalese occupation of Tamil land. When journalists attempt to report on these occupations, they are met with police intimidation. For example, <a target="_blank" class="link-color" href="https://www.tamilguardian.com/content/sri-lankan-police-interrogate-tamil-journalist-reporting-sinhala-settlements-tamil-cattle">the police interrogated a journalist</a>  reporting on the military-assisted Sinahala occupation of Tamil cattle farmers’ land in Batticaloa last year.<br /><br />

                        Tamil resistance in the Northern and Eastern provinces also remain suppressed by police forces. For example, in February of 2021, a peaceful walk organized by North and East civil society organizations to call attention to the plight of Tamil (and Muslim) people was <a target="_blank" class="link-color" href="https://www.einnews.com/pr_news/535195104/tamil-s-walk-for-justice-in-sri-lanka-threatened-by-police-journalists-members-of-parliament-served-with-stay-order">threatened by the police</a> and stay orders were issued, preventing coverage and participation in the walk. The police also disrupt commemoration initiatives in Sri Lanka, denying Tamil people their <a target="_blank" class="link-color" href="https://twitter.com/PEARL_Action/status/1394503329917571080/photo/1"> right to memorialize</a> lives lost during the war.  On many instances, Tamil people engaged in commemorative activities or protests have been taken in policy custody are egregiously <a href="https://itjpsl.com/assets/ITJP-Torture-report-2021-Sep-ENGLISH.pdf" target="_blank" class="link-color">tortured</a> .<br /><br />
                        </p>
                        <div class="additional-reading">
                            <h4>Additional Readings</h4>
                            <a href="http://mil-displacement-report-oakland-institute" target="_blank">Sri Lanka accused of waging 'silent war' as Tamil land is appropriated by army</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <p>While this article concerns army occupation of Tamil land, it is important to note that the police and the army often act together (especially the Counter Terrorism Police Unit).</p>
                            <a href="http://www.jdslanka.org/index.php/news-features/politics-a-current-affairs/971-mounting-harassment-of-journalists-poses-threat-to-media-freedom-in-eastern-sri-lanka" target="_blank">Mounting harassment of journalists poses threat to media freedom in eastern Sri Lanka</a>&nbsp;&nbsp;&#8594;<br />
                            <p>The International Truth and Justice Project calls attention to police and army violence occurring in Sri Lanka. Selected press releases are included below (in English and Tamil). Please note this content is graphic and distributing, and includes discussion of rape and torture. </p>
                            <a href="https://itjpsl.com/press-releases/torture" target="_blank">Sri Lankan security forces torturing and raping young Tamils</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://itjpsl.com/press-releases/not-cricket-sri-lankan-torture" target="_blank">Not Cricket – Sri Lankan Torture</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                        </div>
                        <Reflection text={
                            <>
                            <ol>
                                <li>Whose safety did the police uphold in Sri Lanka?</li>
                                <li>Would you “defund” the police in Sri Lanka?</li>
                                <li>Did the police keep Tamil people safe?</li>
                                <li>Do you think Sri Lankan police will ever be able to protect Tamil people in the Northern and Eastern provinces?</li>
                                <li>Do you think Sri Lankan police can be reformed? Would you give them more money to develop new strategies to “protect” Tamil people?</li>
                            </ol>
                            </>
                        }/>
                        </div>
                    </div> 
                    <div id="Indigenous" class="text-container-md">
                        <h2  class="header">Indigenous Genocide</h2>
                        <p class="text">
                            As a settler colonial state, Canadian laws and institutions exist to legitimate and further the interests of the settlers. Indeed, police—as agents of the state—protect the settlers and enforce their laws. In this sense, they can be described as the physical arm of the settler colonial project,  enacting and facilitating colonial violence. <br /><br />

                            Indeed, despite comprising 4.3% of the population in Canada, Indigenous Peoples <a href="https://www.ctvnews.ca/canada/why-are-indigenous-people-in-canada-so-much-more-likely-to-be-shot-and-killed-by-police-1.4989864" class="link-color" target="_blank">represent</a> 25% of incarcerated men and 35% of incarcerated women. They are also 10-times more likely to be shot by police; from <a href="https://www.reuters.com/article/us-canada-indigenous-police-trfn-idUSKBN23W00F" class="link-color" target="_blank">2007 to 2017</a>, Indigenous Peoples comprised more than a third of people fatally shot by the RCMP. Additionally, Canadian police officers often do not speak the languages of the Indigenous peoples they purportedly serve.  
                        </p>
                        <div class="initial-reading">
                            <h4>Initial Readings</h4>
                            <a href="https://yellowheadinstitute.org/2020/07/15/police-brutality-in-canada-a-symptom-of-structural-racism-and-colonial-violence/" target="_blank">Police Brutality In Canada: A Symptom Of Structural Racism And Colonial Violence</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.aljazeera.com/features/2021/3/24/the-indigenous-people-killed-by-canadas-police" target="_blank">The Indigenous people killed by Canada’s police | Brand Morin  (As of March 24, 2021)</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://canadiandimension.com/articles/view/inquiry-needed-into-police-violence-against-indigenous-peoples" target="_blank">Inquiry needed into police violence against Indigenous peoples</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://youtu.be/azt1uYutKxc" target="_blank">Over Policing Black and Indigenous Lives: Using Policing as a Tool of Genocide</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                        </div>
                        <div class="subsection">
                            <h4>The North West Mounted Police </h4>
                            <p>Canada’s first police force, the North-West Mounted Police (NWMP), were modeled after the Royal Irish Constabulary (RIC). The RIC, agents of the British empire, were tasked with “controlling” the Irish People. Canadian Prime Minister John A. MacDonald hoped the NWMP would “control” Indigenous Peoples, enabling the settler colonial project of Canada to continue unfettered. In particular, while presented as protection for Indigenous Peoples from the Americans, they were key in enabling the settlement of Western Canada.  As such, from the outset of the Canadian state, police forces were created and used to further settler interests. The North-West Mounted Police would eventually become the Royal Canadian Mounted Police (RCMP). 
                            </p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.cbc.ca/listen/cbc-podcasts/203-the-secret-life-of-canada/episode/15798131-s3-the-mounties-always-get-their-land-part-1" target="_blank">The Secret Life of Canada: Season 3, EP 12: The Mounties always get their land (Part 1)(Full Transcript)</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://thenewinquiry.com/a-condensed-history-of-canadas-colonial-cops/" target="_blank">A Condensed History of Canada’s Colonial Cops | M. Gouldhawke</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.thestar.com/news/canada/2020/02/29/enforcers-of-the-colonizers-wetsuweten-crisis-casts-spotlight-on-long-difficult-history-between-rcmp-and-indigenous-canadians.html" target="_blank">‘Enforcers of the colonizers’: Wet’suwet’en crisis casts spotlight on long, difficult history between RCMP and Indigenous peoples | Douglas Quan</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://toronto.citynews.ca/2021/05/19/relation-between-indigenous-and-rcmp-founded-on-oppression-as-strained-as-ever-activist/" target="_blank">Relation between Indigenous and RCMP founded on oppression, as strained as ever: activist | City News</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.cbc.ca/radio/secretlifeofcanada/transcript-the-secret-life-of-canada-season-3-ep-14-the-mounties-always-get-their-land-part-2-1.6168343" target="_blank">The Secret Life of Canada: Season 3, EP 14: The Mounties always get their land (Part 2)(Full Transcript)</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            </div>
                        </div>
                        <div class="subsection">
                            <h4>British Tactics Deployed in Canada and Sri Lanka</h4>
                            <p>
                            When we look at the origins of Canada’s first police force, we uncover how British tactics used in furthering British imperialism and colonialism manifest in the genocide conducted by both states. The North-West Mounted Police were modeled after the Royal Irish constabulary, which was designed by the British to control the Irish people.  British tactics were also taught and repurposed in Sri Lanka. For example, through the Keenie Meenie services, British mercenaries directed military operations that killed Tamil civilians. As such, we see how strategies developed by the British to control colonized and/or oppressed peoples and repress their mobilization were then deployed in both Canada and Sri Lanka.
                            </p>
                            <div class="read-more"><a href="https://www.bbc.com/news/uk-england-london-55071099" target="_blank"><p>Learn More</p><span class="read-more-arrow">&nbsp;&nbsp;&#8594;</span></a></div>
                        </div>
                        <div class="subsection">
                            <h4>“Residential Schools” </h4>
                            <p>The RCMP were the physical agents of “residential schools.” They were the ones who took children from their families, as well as acted as truancy officers for the “schools.” In addition, despite being alerted to allegations of abuse at these “schools”, the RCMP failed to investigate. Many Indigenous people refer to the RCMP as “the people who took our children away.” 
                            </p>
                            <Row>
                                <Col id="card-col-2"><Card name="With the help of the Mounties, the priests piled the children into boats and floated away | Karyn Pugliese aka Pabàmàdiz" link="https://www.nationalobserver.com/2021/06/29/opinion/bury-myth-residential-schools-built-for-education"/></Col>
                                <Col id="card-col-2"><Card name="Schools of Horror: Mistreatment and Murder of Indigenous Children in Canada | Heather Milton-Lightening" link="https://rosalux.nyc/schools-of-horror-mistreatment-and-murder-of-indigenous-children-in-canada/"/></Col>
                                <Col id="card-col-2"><Card name="RCMP 'herded' native kids to residential schools" link="https://www.cbc.ca/news/canada/rcmp-herded-native-kids-to-residential-schools-1.992618"/></Col>
                            </Row>
                        </div>
                        <div class="subsection">
                            <h4>Missing and Murdered Indigenous Women and Girls (MMIWG)</h4>
                            <p>In 2019,  the National Inquiry into Missing and Murdered Indigenous Women and Girls released their final report.  In the report, the police were found to have failed Indigenous communities, and especially Indigenous women and girls. Indigenous people comprise 4.3% of the population of Canada, but Indigenous women <a href="https://www.afn.ca/policy-sectors/mmiwg-end-violence/" target="_blank">make up 16% of female murder victims and 11% of missing women</a>. The report outlined a “denial and unwillingness” on behalf of the police to investigate cases of missing Indigenous women and girls, often invoking harmful stereotypes about these women to dismiss their case.  Jamie L.H, a witness, described in the report: <br /><br />

                            <i>“I feel that the women were deemed as disposable. And it was very, very tragic; their lives were tragic. You know, they were human beings; they were sisters, mothers, daughters, loved ones, wives; partners, aunties, grandmas. They were human beings worthy of dignity and respect, and that wasn’t accorded to them in life.”</i>
                            <br /><br />
                            It is also important to note that police forces have also committed sexual violence against Indigenous women and girls, which was not fully documented in the report.
                            </p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.mmiwg-ffada.ca/final-report/" target="_blank">Reclaiming Power and Place: The Final Report of the National Inquiry into Missing and Murdered Indigenous Women and Girls</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://youtu.be/_dULLnpG9Hg" target="_blank">Pam Palmater interview on MMIWG: Murdered & Missing Indigenous Women & Girls in Canada</a>&nbsp;&nbsp;&#8594;<br />
                            </div>
                        </div>
                        <div class="subsection">
                            <h4>Land Occupation</h4>
                            <p>Police violence inflicted on Indigenous resistance movements is a clear example of how the police protect the political and economic interests of the state. From the onset of the Canadian state, police forces have existed to enable settlement. As such, they have—and continue to—use force against Indigenous resistance movements. In fact, Indigenous land defendanders are often met with greater force compared to other protestors.
                            </p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.thestar.com/news/canada/2020/02/29/enforcers-of-the-colonizers-wetsuweten-crisis-casts-spotlight-on-long-difficult-history-between-rcmp-and-indigenous-canadians.html" target="_blank">‘Enforcers of the colonizers’: Wet’suwet’en crisis casts spotlight on long, difficult history between RCMP and Indigenous peoples</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://youtu.be/RfEOB8JJSSM" target="_blank">Weaponizing Injunctions: How Canada criminalizes Indigenous land defense | Yellowhead Institute </a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.teenvogue.com/story/police-violence-fairy-creek?fbclid=IwAR2vxFeO2yTZ_CH3ZX1nC_XiJrtXAAu2d1qSa9BuN3GymIodVDzjw5aRgyE" target="_blank">Violence at Fairy Creek Is Part of a Reckoning Over Police Brutality in Canada</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://haldimandpress.com/16-3-million-cost-for-opp-to-police-land-back-lane-for-six-months/" target="_blank">$16.3 million cost for OPP to police Land Back Lane for six months</a>&nbsp;&nbsp;&#8594;<br />
                            </div>
                            <Row>
                                <Col id="card-col-2"><Card name="Why are Indigenous people in Canada so much more likely to be shot and killed by police?" link="https://www.ctvnews.ca/canada/why-are-indigenous-people-in-canada-so-much-more-likely-to-be-shot-and-killed-by-police-1.4989864"/></Col>
                                <Col id="card-col-2"><Card name="Canada confronts racism in police in wake of [I]ndigenous deaths" link="https://www.reuters.com/article/us-canada-indigenous-police-trfn-idUSKBN23W00F"/></Col>
                                <Col id="card-col-2"><Card name="Deadly force, neglect kills dozens of Indigenous people in Ontario's justice system" link="https://www.cbc.ca/news/canada/thunder-bay/headlines/deadly-force-indigenous-1.5680668"/></Col>
                                <Col id="card-col-2"><Card name="Indigenous woman seeking apology from Toronto police after case of mistaken identity" link="https://www.thestar.com/news/gta/2019/05/29/black-and-indigenous-people-less-likely-to-trust-toronto-police-survey-finds.html"/></Col>
                            </Row>
                            <Reflection text={
                                <>
                                <ol>
                                    <li>What new information did you learn? Why do you think you have not learned it before?</li>
                                    <li>Whose safety did the police uphold in these cases?</li>
                                    <li>Why is the policing of land defenders significant? Think about the history of the Canadian state.</li>
                                    <li>Does this make you rethink assumptions you held about policing and justice?</li>
                                </ol>
                                </>
                            }/>
                        </div>
                    </div> 
                    <div id="Racism" class="text-container-md">
                        <h2  class="header">Anti-Black Racism</h2>
                        <div class="initial-reading">
                            <h4>Initial Reading</h4>
                            <a href="https://torontolife.com/life/skin-im-ive-interrogated-police-50-times-im-black/" target="_blank">The Skin I’m In: I’ve been interrogated by police more than 50 times—all because I’m black | Desmond Cole</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://youtu.be/WN9XwwcZ7Yk" target="_blank">Over Policing Black and Indigenous Lives: Absenting of Local Policing & the RCMP</a>&nbsp;&nbsp;&#8594;<br />
                        </div>
                        <div class="subsection">
                            <h4>Slavery in Canada</h4>
                            <p>Anti-Black racism requires the erasure of the history and present realities of Black people in Canada. While disproportionate attention is afforded to the Underground Railway—and Canada as a safe haven for enslaved people in the United States—less attention is afforded to the 200-year long history of slavery in this country. In fact, slavery in Canada set the foundation for the anti-Black racism that continues to pervade police forces across the country. As Professor Sibblis states, “Black people were brought here to be slaves. So Black people and freedom are seen as out of place here in Canada.” Indeed, Robyn Maynard explains that when slavery was legal, Black people existing freely were thought to be potential “runaways” or perceived as criminals. Following the official abolishment of slavery, policing was one of the meachansims through which the Canadian could exert control over Black people. </p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://humanrights.ca/story/the-story-of-slavery-in-canadian-history" target="_blank">The story of slavery in Canadian history | Matthew McRae</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://globalnews.ca/news/7048298/policing-in-canada-colonialism-anti-black-racism/" target="_blank">‘Cogs in the colonial wheel’: Why racism in Canada’s police force is as old as policing | Olivia Bowden</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="https://www.cbc.ca/radio/ideas/slavery-s-long-shadow-the-impact-of-200-years-of-enslavement-in-canada-1.4733595" target="_blank">Slavery's long shadow: The impact of 200 years of enslavement in Canada</a>&nbsp;&nbsp;&#8594;<br />
                            </div>
                        </div>
                        <div class="subsection">
                            <h4>Segregation in Canada</h4>
                            <p>Like slavery, segregation is part of the hidden anti-Black history of Canada. There were specific laws that governed and enforced segregation in education, employment, and housing. In addition, many municipalities had “sundown” laws or other relevant bylaws that required Black people to be in their home by a certain time. As Robyn Maynard explains, this effectively criminalized their existence outside of providing labour.</p>
                            <Row>
                                <Col id="card-col-2"><Card name="Q&A: Author Robyn Maynard on Anti-Black Racism, Misogyny, and Policing in Canada" link="https://canadianwomen.org/blog/robyn-maynard/"/></Col>
                                <Col id="card-col-2"><Card name="Time to Expose Canada’s History of Anti-Black Violence to the Mainstream | Kevin Kapenda" link="http://www.capilanocourier.com/2018/02/19/black-history-month-canada-racism-violence/"/></Col>
                            </Row>
                        </div>
                        <div class="subsection">
                            <h4>The Criminalization of Black People in Canada</h4>
                            <p>The anti-Black racism that underlied slavery and segregation continues to pervade Canadian society. As previously explained, these policies and structures effectively criminalized Black people if they existed, with a few exceptions such as providing labour. The enduring legacy of this criminalization if manifest in the disproportionate arrests and charges of Black people today. <br /><br />

                            A study by the Ontario Human Rights Commission found that despite comprising 8.8% of Toronto’s population, they represent <a href="https://www.cbc.ca/news/canada/toronto/black-people-human-right-commission-police-1.5680460" target="_blank" class="link-color">nearly 32% of people</a> charged in Toronto. Importantly, only one fifth of charges laid resulted in a conviction. In fact, charges against Black people are more likely to be withdrawn, pointing to concerns about how these charges are made in the first place. The fatal police shooting rate of Black people <a href="https://www.reuters.com/article/us-canada-race-police-idUSKCN2562SH" target="_blank" class="link-color">in Toronto is 7.29 per million people</a>, whereas in the United States the average is 6.99 per million people. While these statistics are already alarming, it is important to note that they do not begin to address daily encounters with police or non-fatal police violence. <br /><br />

                            The practice of carding clearly exposes the anti-Black racism that pervades Canadian policing. Carding essentially affords the police the power to stop, question, and collect information from individuals without any evidence or knowledge of an offence. A Toronto Star study found that Black people in Toronto are <a href="https://www.thestar.com/news/2012/03/09/known_to_police.html" class="link-color" target="_blank">3.2 times more likely</a> than white people to be documented. In fact, the United Nations Working Group of Experts on People of African Descent has raised concerns about how carding disproportionately impacts Black people in Canada.<br /><br /> 
                            </p>
                        </div>
                        <div class="additional-reading">
                            <h4>Additional Readings</h4>
                            <p>Selected Segments of <a href="https://www.youtube.com/watch?v=G81L0T8RySM&t=296s">Defunding the Police: A discussion on reprioritizing city investments as we build a just Toronto</a>&nbsp;&nbsp;&nbsp;&#8594;</p>
                            <li>[21:56 - 26:26]</li>
                            <li>[36:37 - 37:20]</li><br/>
                            <a href="https://www.macleans.ca/news/canada/desmond-cole-canada-insists-on-being-surprised-by-its-own-racism/" target="_blank">Desmond Cole: 'Canada insists on being surprised by its own racism'</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.npr.org/sections/codeswitch/2015/08/07/427729459/heres-what-black-lives-matter-looks-like-in-canada" target="_blank">Here's What Black Lives Matter Looks Like In Canada</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="http://www.ohrc.on.ca/en/timeline-tps" target="_blank">Timeline of racial discrimination and racial profiling of Black persons by the Toronto Police Service, and OHRC initiatives related to the Toronto Police</a>&nbsp;&nbsp;&#8594;<br /><br />
                            <p><i>Carding:</i></p>
                            <a href="https://youtu.be/mg3l1743gPA" target="_blank">Known to Police</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.amnesty.ca/blog/carding-and-anti-black-racism-in-canada/" target="_blank">Carding and anti-Black racism in Canada | Amnesty International</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.cbc.ca/firsthand/m_features/heres-what-you-need-to-know-about-carding" target="_blank">Here’s What You Need to Know About Carding | CBC Firsthand</a>&nbsp;&nbsp;&#8594;<br /><br />
                            <p><i>More Resources:</i></p>
                            <a href="https://www.sapiens.org/culture/black-lives-matter-civil-war/" target="_blank">Black Lives Matter and Reflections from a Civil War</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.baltimoresun.com/entertainment/arts/bs-ae-coates-author-20150718-story.html" target="_blank">Ta-Nehisi Coates on the roots of racial violence</a>&nbsp;&nbsp;&#8594;<br />
                            <a href="https://www.cbc.ca/news/canada/toronto/siu-race-based-data-1.5606505" target="_blank">Provincial police watchdog to start collecting race-based data</a>&nbsp;&nbsp;&#8594;<br />
                        </div>
                        <Reflection text={
                            <>
                            <ol>
                                <li>What new information did you learn? Why do you think you have not learned it before?</li>
                                <li>Whose safety did the police uphold in these instances?</li>
                                <li>How do the practice of slavery and segregation echo in police forces today?</li>
                                <li>In Desmond’s Cole interview with Maclean’s, he shares stories of young Black students being suspended in schools? How does this relate to anti-Black racism in policing?</li>
                                <li>What does the <a href="http://www.ohrc.on.ca/en/timeline-tps">timeline</a> created by the OHRC tell you?</li>
                                <li>Race-based data has rarely, if ever, been collected by police forces in Toronto. Why do you think this is?</li>
                                <li>Has this information changed your perceptions of justice and policing?</li>
                                <li>Has this changed how you understand “criminality”?</li>
                            </ol>
                            </>
                        }/>
                    </div>
                    <div id="Policing" class="text-container-md">
                        <h2  class="header">Policing of Tamils in Canada</h2>
                        <p>To disrupt the narrative of generosity and benevolence often afforded to the Canadian state vis-a-vis their treatment of Tamils, this section will shed light on how Tamil people faced heightened surveillance at the hands of the police, as well as the broader security apparatus, in Canada. In doing so, it attempts to call into question the interests served by the police and the safety they provide. <br/><br/>
                        It is also imperative to connect the policing, surveillance, and criminalization of Tamil people in Canada to the genocidal project of Sri Lanka. Indeed, through these processes, Tamil bodies are perceived as threats and undeserving of protection. This enables international bodies to remain silent on Tamil genocide. 
                        </p>
                        <div class="subsection">
                            <h4>Toronto Police Tamil Task Force & Project 1050</h4>
                            <p>
                                The Toronto Police’s Tamil Task Force was established in the 1990s, primarily in response to the violence associated with “Tamil gangs.” At the time, Tamil gangs often served as community and protection for young Tamil men who were reeling with the trauma—both lived and intergenerational—of Tamil genocide, displacement, and migration. In this sense, the violence was largely a social problem, and the subsequent surveillance tactics deployed by the police only served to exacerbate it. Indeed, as Harini Sivaligam states, “the existence of the Tamil Task Force was institutionalized racial profiling.”<br /><br />
                                A few years later, in 2001, Toronto Police and Citizenship and Immigration Canada conducted a raid entitled Project 1050. As a result, 51 Tamil men were arrested, and the subsequent proceedings marked the first time street gangs constituted “organized crime” in Canadian immigration law (Phillipupillai 2013, p.60).<br /><br /> 
                                The media narrative at the time incessantly reported on these “Tamil gangs”, forging links to the LTTE and to the broader Tamil resistance movement. In doing so, it reinforced harmful stereotypes and racialized Tamil people as criminals. As Gillian Phillipupillai writes,“[i]n 2006 the fact that only two of the 51 men arrested during the 2001 ‘Project 1050’ raid had been deported was cited as further evidence that these ‘thugs’ and ‘terrorists’ were misusing the Canadian legal system (Shephard, 2006), rather than as evidence that many had been unjustly targeted and racially profiled by the Toronto Police Service and Citizenship and Immigration Canada.”
                            </p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://escholarship.mcgill.ca/concern/theses/2f75r958f" target="_blank">Discourses of fear and victimization: the impact of national security legislation on the Tamil Canadian community | Harini Sivalingam</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <li>Pages 95-97</li><br/>

                                <a href="https://tspace.library.utoronto.ca/bitstream/1807/42640/1/Philipupillai_Gillian_G_MA_thesis.pdf" target="_blank">The Marking of Tamil Youth as Terrorists and the Making of Canada as a White Settler Society | Gillian Philipupillai</a>&nbsp;&nbsp;&#8594;<br />
                                <li>Pages 55-57</li><br/>

                                <a href="When Memory Outlives | Mirusha Yogarajah" target="_blank">Slavery's long shadow: The impact of 200 years of enslavement in Canada</a>&nbsp;&nbsp;&#8594;<p>(section entitled “Policing and surveillance”)</p><br />
                            </div>
                        </div>
                        <div class="subsection">
                            <h4 id="TheCriminalizationOfTamilExistence">The criminalization of Tamil existence and resistance in the post-911 era</h4>
                            <p>After 9/11 and the subsequent War on Terror, Tamil people were increasingly criminalized. This became particularly acute following the designation of the Liberation Tigers of Tamil Eelam (LTTE) and the World Tamil Movement (WTM) as terrorist organizations in Canada. As a result, the Tamil resistance movement was construed as “terrorism.” <br /><br />
                            Sensationalized media headlines and political discourse further exacerbated this problem. Indeed, as Harini Sivalingam outlines in <i>Discourses of Fear and Victimization: The Impact of National Security Legislation on the Tamil Canadian Community,</i> this had significant impacts on the entire community. A linkage was formed between Tamil and terror in the public imagination, rendering Tamil people more vulnerable to discrimination and surveillance. <br /><br />
                            A few examples outlined in <i>Discourses of Fear and Victimization: The Impact of National Security Legislation on the Tamil Canadian Community:</i> 
                            <ul>
                                <li>A Tamil youth group conducting workshops for Tamil students at a Toronto school were asked to conduct the workshops in English, despite the students feeling more comfortable communicating in Tamil. This request was made so administrators could understand what was being said and to ensure there would be no promotion of the LTTE. (p.75)</li>
                                <li>A young Tamil male wearing an ordinary t-shirt with a tiger on it stopped by a school official and told he could not wear a shirt that promoted the Tamil Tigers (p.75)</li>
                                <li>A Tamil individual speaking out on human right abuses in Sri Lanka during his private time having his duties restricted at work (p.74) </li>
                                <li>The University of Waterloo Tamil Student Association (TSA) faced multiple instances of discrimination by campus administration,  both before and after the arrest of young Tamil men affiliated with the TSA. These additional measures were not required of other campus clubs. 
                                <ul>
                                    <li>The TSA booked a frosh week event at a campus pub. However, the day before, they were informed that they would be required to have police officers present. As it was a last-minute request, the TSA could not arrange for the hiring of officers, and the event was cancelled. (p.79)</li>
                                    <li>When the TSA organized a Tsunami relief fundraiser on campus, police officers checked the identification of everyone who attended and activities (including the music that was played) had to be conducted in English (p.79). </li>
                                </ul>
                                </li>
                                <li>At the University of Ontario Institute of Technology, Tamil students were initially unable to form a TSA because the university conflated the word “Tamil” with “terrorist” (p.81)</li>
                                <li>Tamil political candidates being described as “terrorists” as a means of discrediting their campaigns</li>
                                </ul>
                            This construction of terror and criminality had chilling effects on the community’s ability to exist as Tamil people. As Harini Sivalingam states, “many Tamil students...scaled back their involvement with the TSA and the Tamil community as a whole” (p.81). In Sri Lanka, the PTA criminalized Tamil political movements, and eventually, Tamil existence. In Canada, the anti-terrorism infrastructure—emboldended in the post-911 era—functioned similarly.
                            </p>
                        </div>
                        <div class="subsection">
                            <h4>Manufacturing Security Threats: The Case of Tamil Refugees </h4> 
                            <p>This association of the Tamil identity and terrorism had chilling effects on Canada’s treatment of Tamil refugees. Between 2009 and 2010, over 560 Tamil asylum-seekers fled persecution and arrived on the Canadian coast aboard either the Ocean Lady or MV Sun Sea. However, their arrival was manufactured to be a security threat to Canada. Indeed, the majority of refugees were detained upon their arrival, and many were accused of human smuggling. Once again, the Tamil identity was falsely conflated with “criminality” and “terrorism.”  In manufacturing a security threat, the Canadian state painted Tamil refugees as undeserving of safety.</p>
                            <div class="additional-reading">
                                <h4>Additional Readings</h4>
                                <a href="https://www.themigrationinitiative.ca/post/xenophobia-criminalization-and-securitization-responses-to-the-arrival-of-tamil-refugees-2009-10" target="_blank">Xenophobia, Criminalization and Securitization: Responses to the Arrival of Tamil Refugees 2009-10 | Rahul Balasundaram</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                                <a href="https://escholarship.mcgill.ca/concern/theses/2f75r958f" target="_blank">Discourses of fear and victimization: the impact of national security legislation on the Tamil Canadian community | Harini Sivalingam</a>&nbsp;&nbsp;&#8594;<br />
                                <a href="http://www.tamilcanadian.com/article/2498">Tamils say media fell for Sri Lanka's tale</a>&nbsp;&nbsp;&#8594;
                            </div>
                        </div>
                        <div class="initial-reading">
                            <h4>Initial Reading</h4>
                            <a href="https://torontolife.com/life/skin-im-ive-interrogated-police-50-times-im-black/" target="_blank">The Skin I’m In: I’ve been interrogated by police more than 50 times—all because I’m black | Desmond Cole</a>&nbsp;&nbsp;&nbsp;&#8594;<br />
                            <a href="https://youtu.be/WN9XwwcZ7Yk" target="_blank">Over Policing Black and Indigenous Lives: Absenting of Local Policing & the RCMP</a>&nbsp;&nbsp;&#8594;<br />
                        </div>
                        <p>As this resource has noted, recent years have seen a Tamil identity defined by (conventional) economic and political success. This identity has insidiously erased differences within the community, constructing a monolithic Toronto Tamil identity. In doing so, it has erased the enduring realities of the policing of Tamils in Toronto;  many community members continue to feel the lingering effects of the criminalization of Tamils in the 1990s and early-mid 2000s.</p>
                        <Reflection text={
                            <>
                            <ol>
                                <li>How does learning about the policing of Tamils in Canada make you feel? Why do you think you feel that way? </li>
                                <li>What new information did you learn? </li>
                                <li>How does the policing of Tamils in Canada connect to Tamil genocide in Sri Lanka?</li>
                                <li>Has this information changed your perceptions of justice and policing?</li>
                                <li>Has this changed how you understand “criminality”? </li>
                                <li>Do you have experiences with the police? Reflect on those interactions and how they made you feel (if you feel comfortable and safe doing so).</li>
                                <li>How does policing impact the Tamil community today? How do the labels of “terror” and “criminality” impact the community? </li>
                            </ol>
                            </>
                        }/>
                    </div>      
                  </div>
              </td>
          </tr>
      </table>
    </div>
  );
}